import { auth, hasFeatures } from '@middleware'
import routesFactory from '@router/routes-factory'
import store from '@store'
import { ROUTE_MAPPER } from '../utils'

const routerConfig = {
    baseURI: 'insights',
    module: 'Insights',
    middleware: [
        auth('admin', { name: '401' }),
        hasFeatures(['InsightsLearners', 'InsightsContent'], { name: '404' }),
    ],
}

const routes =  routesFactory(routerConfig, [
    {
        path: 'filters',
        name: 'insights.filters.index',
        component: (): Promise<any> => import('../InsightsFiltersIndex.vue'),
    },
    {
        path: 'content/modules/:moduleId/answers',
        name: 'insights.module.aggregated',
        props: true,
        component: (): Promise<any> => import('../InsightsModuleAggregatedAnswers.vue'),
    },
    /**
     * Answers insights routes
     */
    {
        path: 'learners/users/:userId/modules/:moduleId/answers',
        name: 'insights.module.answers',
        props: ({ params }): object => ({
            moduleId: Number(params.moduleId),
            userId: Number(params.userId),
        }),
        component: (): Promise<any> => import('../InsightsModuleAnswers.vue'),

    },
    {   // Learner insights alias
        path: 'learners',
        name: 'insights.learners.index',
        redirect: {
            name: 'insights.index',
            params: {
                type: 'learners',
                scope: 'users',
            },
        },

    },
    {   // Content insights alias
        path: 'content',
        name: 'insights.content.index',
        redirect: {
            name: 'insights.index',
            params: {
                type: 'content',
                scope: 'learning-paths',
            },
        },

    },
    {
        path: ':type/:scope/:group?',
        name: 'insights.index',
        component: (): Promise<any> => import('../InsightsIndex.vue'),
        props: (route) => {
            const { type, scope, group } = route.params
            const schemas: InsightsSchemaModel[] = store.state.Insights.schemas
            const searchSchema = ROUTE_MAPPER[type]?.[scope]?.[group ?? 'index']
            const schema = schemas.find((s) => s.grouping === searchSchema)

            return {
                type,
                scope,
                group,
                schema: schema ?? null,
            }
        },
        beforeEnter: async (to, _from, next) => {
            const { type, scope, group } = to.params
            await store.dispatch('Insights/getSchemas')
            const schemas: InsightsSchemaModel[] = store.state.Insights.schemas

            const schema = ROUTE_MAPPER[type]?.[scope]?.[group ?? 'index']

            const schemaExists = schemas.some((s) => s.grouping === schema)

            if (schemaExists)
                next()
            else {
                next({ name: '404' })
            }
        },

    },

])

export default routes