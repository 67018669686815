<template>
    <div>
        <OpenAiChatMessage
            v-if="initialChat"
            :value="initialChat"
            disable-copy
        />
        <OpenAiChatMessage
            v-for="(message, index) in messages"
            :key="index"
            :value="message"
        />
        <spinner
            v-if="isBusy"
            class="justify-content-start d-inline-flex border p-2 mb-2 rounded-lg"
            size="2xs"
            animation="wave"
        />
    </div>
</template>

<script lang="ts">
    import { defineComponent } from 'vue'
    import OpenAiChatMessage from './OpenAiChatMessage.vue'
    import Spinner from '@common/components/Spinner.vue'

    export default defineComponent({
        components: {
            OpenAiChatMessage,
            Spinner,
        },

        data() {
            return {
                observer: null as MutationObserver | null,
                firstScroll: true,
            }
        },

        computed: {
            initialMessage(): string {
                return this.$store.state.OpenAI?.initialMessage
            },

            initialChat(): RecursivePartial<OpenAIMessage> {
                const needHelp = `\n\n[${this.$t('TERMS.NEED_HELP')}](https://help.learningbank.io/en/articles/58596-ai-learning-helper)`
                const responses = [
                    { content: String(this.$t('OPENAI.GREETING')) },
                ]

                if (this.initialMessage)
                    responses.push({
                        content: String(this.$t(this.initialMessage)).concat(needHelp),
                    })

                return {
                    response: responses,
                }
            },

            messages(): OpenAIMessage[] {
                return this.$store.state.OpenAI?.messages ?? []
            },

            isBusy(): boolean {
                return this.$store.state.OpenAI?.busy ?? false
            },
        },

        mounted() {
            this.scrollBottom()
            this.setupObserver()
        },

        beforeDestroy() {
            this.observer?.disconnect()
        },

        methods: {
            setupObserver() {
                this.observer = new MutationObserver(() => {
                    this.scrollBottom()
                })

                this.observer.observe(this.$el, {
                    childList: true,
                })
            },

            scrollBottom() {
                let container: any = this.$el

                while (container && container !== document.body) {
                    const style = window.getComputedStyle(container)

                    if (['auto', 'scroll'].includes(style.overflowY)) {
                        if (this.firstScroll) this.firstScroll = false
                        else container.style.scrollBehavior = 'smooth'

                        container.scrollTop = container.scrollHeight
                        break
                    }

                    container = container.parentNode
                }
            },
        },

    })
</script>