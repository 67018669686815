<template>
    <page-wrapper v-bind="layout">
        <template #before>
            <portal-target
                class="sticky-top"
                name="header"
            />
            <portal-target
                name="page-before"
            />
        </template>
        <router-view
            @input="onLayout"
        />
        <template #after>
            <portal-target
                slim
                name="page-after"
            />
        </template>
        <modal-wrapper />
        <OpenAiChatSidebar v-if="$hasFeatures('OpenAI')" />
        <knowbot-chat-modal />
        <flash-wrapper />
    </page-wrapper>
</template>

<script lang="ts">
    import Vue from 'vue'
    import { ModalWrapper } from '@common/Modals'
    import PageWrapper from '@common/Page/PageWrapper.vue'
    import KnowbotChatModal from '@modules/Knowbot/components/KnowbotChatModal.vue'
    import OpenAiChatSidebar from '@modules/OpenAI/components/OpenAiChatSidebar.vue'


    export default Vue.extend({
        components: {
            ModalWrapper,
            PageWrapper,
            KnowbotChatModal,
            OpenAiChatSidebar,
        },

        data() {
            return {
                layout: {},
            }
        },

        methods: {
            onLayout(value: object): void {
                this.layout = value
            },
        },
    })
</script>

<style lang="scss" scoped>
    .modal-wrapper, .flash-wrapper {
        position: absolute;
    }
</style>
