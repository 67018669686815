<template>
    <b-sidebar
        v-model="isVisible"
        right
        lazy
        text-variant="body"
        bg-variant="white"
        sidebar-class="ai-chat-sidebar"
        header-class="p-3 g-2"
        body-class="px-3 d-flex flex-column"
        footer-class="p-3"
        :width="$device.isMobile ? '100%' : '30rem'"
        shadow="sm"
    >
        <template #header="{ hide }">
            <span class="flex-grow-1">
                {{ $t('OPENAI.ASK_AI') }}
                <fa
                    :icon="['fas', 'sparkles']"
                    class="svg-gradient ml-2"
                />
            </span>

            <btn
                v-if="messages.length"
                :label="$t('TERMS.RESET')"
                pill
                size="sm"
                @click="reset"
            />

            <btn
                class="text-reset"
                :icon="['fal', 'times']"
                variant="link"
                pill
                @click="hide"
            />
        </template>

        <!-- Push content to bottom -->
        <div class="mb-auto" />

        <OpenAiChatMessages />

        <template #footer>
            <OpenAiChatInput
                v-if="loaded"
                :placeholder="$t('TERMS.ASK')"
                :disabled="isBusy"
                @submit="onSubmit"
            />
        </template>
    </b-sidebar>
</template>

<script lang="ts">
    import { defineComponent } from 'vue'
    import { BSidebar } from 'bootstrap-vue'
    import OpenAiChatInput from './OpenAiChatInput.vue'
    import OpenAiChatMessages from './OpenAiChatMessages.vue'

    export default defineComponent({
        components: {
            BSidebar,
            OpenAiChatInput,
            OpenAiChatMessages,
        },

        data() {
            return {
                loaded: false,
            }
        },
        computed: {
            isVisible: {
                get(): boolean {
                    return this.$store.state.OpenAI?.sidebarOpen ?? false
                },
                set(value: boolean): void {
                    this.$store.commit('OpenAI/SET_SIDEBAR_OPEN', value)
                },
            },

            isBusy(): boolean {
                return this.$store.state.OpenAI?.busy ?? false
            },

            messages(): OpenAIMessage[] {
                return this.$store.state.OpenAI?.messages ?? []
            },
        },

        async mounted() {
            await this.$store.load('OpenAI')
            this.loaded = true
        },

        methods: {
            async onSubmit(message: string): Promise<void> {
                try {
                    await this.$store.dispatch('OpenAI/chat', message)
                } catch {
                    this.$flash('danger', this.$t('ERROR.SOMETHING_WENT_WRONG'))
                }
            },

            reset(): void {
                this.$store.dispatch('OpenAI/reset')
            },
        },

    })
</script>

<style lang="scss">
    @import "@scss/vue.scss";

    section.content:has(.topbar) {
        .ai-chat-sidebar {
            @include media-breakpoint-up(md) {
                top: $main-nav-width;
                height: calc(100vh - #{$main-nav-width});
            }
        }
    }
</style>