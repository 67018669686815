import Vue, { PropType, VNode } from 'vue'
import { cloneDeep } from 'lodash'

/**
 * Table mixin that houses shared properties across all table components
 */
export default Vue.extend({
    props: {
        /**
         * Unique id of table to refresh via global event
         *
         * fx. `this.$root.$emit('bv::refresh::table', 'my-id-here')`
         */
        id: { type: String as PropType<string>, default: '' },
        /**
         * Ovewrite fields if not using table-column component
         */
        fields: { type: Array as PropType<any[]>, default: (): any[] => [] },
        /**
         * Rows can be selected this emits an event on table [@selected] with
         * array of rows as payload.
         * Prop can also recieve a callback that gives you each data item as argument
         * and needs to return a boolean if that specific item can be selected.
         * fx. `selectable="(item) => item.notSelectable"`
         */
        selectable: {
            type: [Boolean, Function] as PropType<boolean | ((value: any) => boolean)>,
            default: false,
        },
        /**
         * Set preselected items
         */
        preSelect: { type: Array as PropType<any[]>, default: (): never[] => [] },
        /**
         * Adds a search input into the header slot and searches all cols
         */
        searchable: { type: Boolean as PropType<boolean>, default: false },
        /**
         * Allow external comonent to handle searching,
         * this overwrites searchable prop as long as its not undefined
         */
        searchQuery: { type: String as PropType<string>, default: undefined },
        /**
         * Disable empty states on table
         */
        hideEmpty: { type: Boolean, default: false },
        /**
         * Cell content is vertically centered
         */
        centerV: { type: Boolean, default: true },
        /**
         * Display table as a card
         */
        card: { type: Boolean, default: false },
        /**
         * Disable select all checkbox
         */
        disableSelectAll: { type: Boolean as PropType<boolean>, default: false },

        /**
         * Apply class to row based on row entity
         */
        rowClass: {
            type: [Array, Object, String, Function] as PropType<string[] | object | string | '(item: any, object: any) => object | string | string[]'>,
            default: undefined,
        },
        /**
         * Apply class to inner b-table component
         */
        tableClass: {
            type: [String, Array, Object] as PropType<string | any[] | object>,
            default: null,
        },
        /**
         * Minimum height of table, in order to fix
         * issues with filter dropdowns.
         */
        minHeight: {
            type: String as PropType<string>,
            default: 'auto',
        },
        /**
         * Run a transformer function on every item being displayed in the table
         * There are few magic properties you can use that have a functional impact
         * - `$disabled` - will disable the checkbox if table is selectable
         * - `$preselect` - will set the checkbox visually as selected
         */
        itemTransformer: {
            type: Function as PropType<(value: any) => any>,
            default: null,
        },
        /**
        *  Hover effect on selected row in the table
        */
        hover: {
            type: Boolean as PropType<boolean>,
            default: true,
        },

        /**
         * Set default sorting on the initial load.
         * this should reflect the `field` prop on one of the the `<table-column>`
         * you want to sort on.
         * If using on a remote table, make sure that sort key is supported by EP.
         *
         * Note: if you want to default sorting to be descending you need
         * to prefix the string with hyphen e.g. `-name`
         */
        defaultSort: {
            type: String as PropType<string>,
            default: null,
        },
    },

    data() {
        return {
            fieldsLocal: this.fields,
            currentPage: 1,
            totalItems: 0,
            perPage: 10,
            queryLocal: '',
            selectedItems: [] as any[],
            selectAll: false,
            sortBy: this.defaultSort?.replace(/^-/, '') ?? null,
            sortDesc: this.defaultSort?.startsWith('-') ?? false,
        }
    },

    computed: {
        query(): string {
            return this.searchQuery || this.queryLocal
        },

        templateFields(): any[] {
            return this.tableFields.filter((field: any) => field.template)
        },

        headerTemplateFields(): any[] {
            return this.tableFields.filter((field: any) => field.headerTemplate)
        },

        tableFields(): any[] {
            return this.fieldsLocal.filter((field: any) => !field.hidden )
        },

        isSearchable(): boolean {
            return this.searchable && this.searchQuery === undefined
        },

        lastFieldKey(): string {
            return this.tableFields[this.tableFields.length - 1].key
        },

        shouldSaveColumnState(): boolean {
            return !!this.id
        },

        fieldKeys(): string[] {
            return this.fieldsLocal.map(({ key }) => key)
        },

        hiddenFieldKeys(): string[] {
            return this.fieldsLocal
                .filter((field) => !!field.hidden)
                .map(({ key }) => key)
        },
    },

    watch: {
        selectedItems: {
            handler(value): void {
                /**
                 * Emits when user has selected a row in table
                 * @param {array} items a collection of selected rows
                 */
                this.$emit('selected', value)
            },
            deep: true,
        },
    },

    mounted() {
        this.init()
    },

    methods: {
        init(resetPagination = false): void {
            if (resetPagination)
                this.currentPage = 1

            if (this.preSelect?.length ?? false)
                this.selectedItems = cloneDeep(this.preSelect)

            if (!this.$slots.default)
                return

            const fields = this.$slots.default
                .filter((column: VNode) => column.componentInstance)
                .map((column: any) => column.componentInstance.getColumn())

            if (!fields.length)
                return

            if (this.selectable)
                fields.unshift({ key: 'select', label: '', thStyle: { width: '1%' } })

            this.fieldsLocal = fields
        },

        /**
         * Add and remove selected items based on checkboxes
         */
        onRowSelect(checked: boolean, item: any): any {
            if (item._selectable === true) return

            if (checked) {
                return this.selectedItems.push(item)
            }

            const index = this.selectedItems
                .findIndex((selectedItem: any) => selectedItem.id === item.id)

            return this.selectedItems.splice(index, 1)
        },

        /**
         * Transform data before giving it to table.
         */
        itemsTransformer(data: any[]): any[] {
            if (this.selectable) {
                data = data.map((item: any) => {
                    item = this.itemTransformer?.(item) ?? item

                    item._selected = this.selectAll || item.$preselect
                        ? true
                        : !!this.selectedItems.find((selectedItem) => selectedItem.id === item.id)

                    if (typeof this.selectable === 'function') {
                        item._selectable = this.selectable(item)
                    }

                    return item
                })
            }

            return data
        },
    },
})
