<template>
    <btn
        v-if="show"
        :variant="variant"
        :icon="iconLocal"
        :label="labelLocal"
        v-bind="$attrs"
        @click="toClipboard"
    />
</template>

<script lang='ts'>
    import Vue, { PropType } from 'vue'
    import { TranslateResult } from 'vue-i18n'

    export default Vue.extend({
        props: {
            text: {
                type: String,
                required: true,
            },

            show: {
                type: Number,
                default: 1,
            },

            /** Buttons color variants */
            variant: {
                type: String as PropType<string>,
                default: 'default',
                validator: (value: string): boolean => [
                    'default',
                    'link',
                ].includes(value),
            },

            /**
             * Prepended icon in button. Takes the same value
             * as our font awesome component
             */
            icon: {
                type: [Array, String] as PropType<string[] | string>,
                default: 'link',
            },

            noIcon: {
                type: Boolean as PropType<boolean>,
                default: false,
            },

            label: {
                type: String as PropType<string>,
                default: null,
            },

            labelSuccess: {
                type: String as PropType<string>,
                default: 'TERMS.COPIED',
            },
        },

        data() {
            return {
                copied: false,
            }
        },

        computed: {
            labelLocal(): string | TranslateResult | null {
                if (!this.label)
                    return null

                return this.copied
                    ? this.$t(this.labelSuccess)
                    : this.label
            },

            iconLocal(): string[] | null {
                if (this.noIcon)
                    return null

                return Array.isArray(this.icon)
                    ? this.icon
                    : ['fal', this.icon]
            },
        },

        methods: {
            async toClipboard(): Promise<void> {
                await navigator.clipboard.writeText(this.text)
                this.copied = true
                this.$emit('copied')

                setTimeout(()=> {
                    this.copied = false;
                    (this.$el as HTMLButtonElement).blur()
                }, 3000)
            },
        },
    })
</script>
