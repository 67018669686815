<template>
    <component
        :is="tag"
        ref="element"
        :class="{ 'disabled': disabled }"
        class="v-editable"
        :contenteditable="!disabled"
        :data-placeholder="placeholder"
        @keypress.enter="onEnter"
        @input="onInput"
        @blur="onBlur"
        @paste="onPaste"
        @keydown="onKeyDown"
    />
</template>

<script lang="ts">
    import Vue, { PropType, VueConstructor } from 'vue'
    import { lineBreakToHtml } from '@utils'

    interface Refs {
        $refs: {
            element: HTMLElement;
        };
    }

    /**
     * A little wrapper for `contenteditable` attribute
     * Allows you to use v-model, define a tag type and a placeholder
     */
    export default (Vue as VueConstructor<Vue & Refs>).extend({
        props: {
            /**
             * Editable string
             *
             * @model
             */
            value: {
                type: String as PropType<string>,
                default: null,
            },

            /**
             * Placeholder text displayed on empty value
             */
            placeholder: {
                type: String as PropType<string>,
                default: '',
            },

            /**
             * Render as valid HTML tag
             */
            tag: {
                type: String as PropType<string>,
                default: 'div',
            },

            /**
             * Allow the contenteditable to be in multiple lines
             */
            multiLine: {
                type: Boolean as PropType<boolean>,
                default: false,
            },

            /**
             * Restrict the content to a max number of characters
             */
            maxLength: {
                type: Number as PropType<number>,
                default: null,
            },

            /**
             * Disable editing
             */
            disabled: {
                type: Boolean as PropType<boolean>,
                default: false,
            },
            /**
             * Allow html, uses innerHtml over innerText
             */
            html: {
                type: Boolean as PropType<boolean>,
                default: false,
            },

            autofocus: {
                type: Boolean as PropType<boolean>,
                default: false,
            },
        },

        watch: {
            value(value): void {
                if (value !== this.currentContent()){
                    this.updateContent(value)
                }
            },
        },

        mounted() {
            this.updateContent(this.value ?? '')
            this.$nextTick(() => this.autofocus && this.$refs.element?.focus())
        },

        methods: {
            currentContent(): string {
                if (this.$refs.element.innerHTML === '<br>')
                    this.$refs.element.innerHTML = ''

                if (this.$refs.element.innerText === '\n')
                    this.$refs.element.innerText = ''

                return this.html
                    ? this.$refs.element?.innerHTML
                    : this.$refs.element?.innerText
            },

            updateContent(value: string): void {
                if (this.html)
                    this.$refs.element.innerHTML = lineBreakToHtml(value)
                else
                    this.$refs.element.innerText = value
            },

            onInput(): void {
                this.$emit('input', this.currentContent())
            },

            onEnter(event: KeyboardEvent): void {
                if (!this.multiLine) {
                    event.preventDefault()

                    /**
                     * Fires when multiline is disabled and user hits return
                     *
                     * @property {string} value current text content
                     */
                    this.$emit('returned', this.currentContent())
                }
            },

            onBlur(event: FocusEvent): void {
                /**
                 * Fires when user blurs from input
                 *
                 * @property {FocusEvent} event FocusEvent object
                 * @property {string} value current value of input
                 */
                this.$emit('blur', event, this.currentContent())
            },

            onKeyDown(event: KeyboardEvent): boolean | void {
                const content = this.currentContent()
                const selection = window.getSelection()?.toString() || ''
                const remainingLength = content.length - selection.length - Number(event.code === 'Space')

                if (!this.isAllowedKeyCode(event) && this.maxLength && remainingLength >= this.maxLength) {
                    event.preventDefault()

                    return false
                }
            },

            onPaste(event: ClipboardEvent): boolean | void {
                event.preventDefault()

                let paste = event?.clipboardData?.getData('text/plain') || ''
                const selection = window.getSelection()
                const selected = selection?.toString() || ''
                const content = this.currentContent()
                const remainingLength = (paste.length + content.length) - selected.length

                if (this.maxLength !== null && remainingLength > this.maxLength)
                    paste = paste.slice(0, this.maxLength)

                const textNode = document.createTextNode(paste)

                // Insert text at the current position of caret
                const range = selection?.getRangeAt(0)
                range?.deleteContents()

                range?.insertNode(textNode)
                range?.selectNodeContents(textNode)
                range?.collapse(false)

                selection?.removeAllRanges()
                range && selection?.addRange(range)

                this.$emit('input', this.currentContent())
            },

            /**
             * Check if a keycode is allowed when max limit is reached
             * ctrlKey for control key
             * metaKey for command key on mac keyboard
             * @param {any} eventKeycode
             * @returns boolean
             */
            isAllowedKeyCode(event: KeyboardEvent): boolean {
                return event.code === 'Backspace'
                    || event.code === 'Delete'
                    || event.code.includes('Arrow')
                    || event.ctrlKey
                    || event.metaKey
            },
        },
    })
</script>

<style lang="scss" scoped>
    @import '@scss/vue.scss';

    .v-editable {
        cursor: text;
        outline: 0;

        &.disabled {
            cursor: default;
        }

        &:empty:before {
            content: attr(data-placeholder) '\a0';
            color: inherit;
            opacity: .4;
            filter: grayscale(.4);
        }
    }
</style>
