import Vue, { VueConstructor } from 'vue'
import { debounce } from 'lodash'

declare module 'vue/types/vue' {
    interface Vue {
        $device: Readonly<DeviceInfo>;
    }
}

interface DeviceInfo {
    /** Device screen width */
    width: number;
    /** Device screen height */
    height: number;
    /** Current screen breakpoint */
    breakpoint: TShirtSizes;
    /** Is device in landscape */
    landscape: boolean;
    /** Is device in portrait */
    portrait: boolean;
    /** Is device touch enabled */
    touch: boolean;
    /* is device mobile */
    isMobile: boolean;
    /* is device tablet */
    isTablet: boolean;
    /* is device desktop */
    isDesktop: boolean;

}

const DEBOUNCE_MS = 100

const BREAKPOINTS: { [key: string]: { min: number; max: number } } = {
    xs: { min: 0, max: 575 },
    sm: { min: 576, max: 767 },
    md: { min: 768, max: 991 },
    lg: { min: 992, max: 1439 },
    xl: { min: 1440, max: Infinity },
}

/**
 * Device Plugin. get information about devices's
 * screen on runtime, updates when resize occurs
 */
class DevicePlugin {
    device: DeviceInfo

    constructor() {
        this.device = this.createDevice()
        this._attachResize()
        this._setDeviceInfo()
    }

    /**
     * Mobile first query.
     * equivalent to sass media-breakpoint-up function
     */
    private mqUp(size: TShirtSizes): boolean {
        return this.device.width >= BREAKPOINTS[size].min
    }

    /**
     * Equivalent to sass media-breakpoint-down function
     */
    private mqDown(size: TShirtSizes): boolean {
        return this.device.width <= BREAKPOINTS[size].max
    }
    /**
     * Equivalent to sass media-breakpoint-only function
     * You can pass multiple arguments if you want to apply
     * some thing on 2+ breakpoints
     */
    private mqOnly(...sizes: TShirtSizes[]): boolean {
        return sizes.includes(this.device.breakpoint)
    }

    private createDevice(): DeviceInfo {
        const device = Vue.observable({
            width: 0,
            height: 0,
            breakpoint: 'xs' as TShirtSizes,
            landscape: false,
            portrait: false,
            touch: 'ontouchstart' in window,
            isMobile: false,
            isTablet: false,
            isDesktop: false,
        })

        return device
    }


    private _attachResize(): void {
        window.addEventListener(
            'resize',
            debounce(this._setDeviceInfo.bind(this), DEBOUNCE_MS),
        )
    }

    private _setDeviceInfo(): void {
        // Screen size
        this.device.width = window.innerWidth
        this.device.height = window.innerHeight
        // Orientation
        this.device.portrait = window.innerWidth < window.innerHeight
        this.device.landscape = window.innerWidth > window.innerHeight
        // Current breakpoint
        for (const [breakpoint, { min, max }] of Object.entries(BREAKPOINTS)) {
            if (this.device.width >= min && this.device.width <= max) {
                this.device.breakpoint = breakpoint as TShirtSizes
            }
        }

        // isDevice booleans
        this.device.isMobile = this.mqDown('sm')
        this.device.isTablet = this.mqOnly('md')
        this.device.isDesktop = this.mqUp('lg')
    }

    /**
     * Installation method for Vue.use()
     */
    static install(Vue: VueConstructor): void {
        Vue.prototype.$device = new DevicePlugin().device
    }
}

Vue.use(DevicePlugin)

export default new DevicePlugin().device
