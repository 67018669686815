<template>
    <div class="row">
        <!-- Cropper area -->
        <div
            class="order-lg-2"
            :class="hasToolbar ? 'col-lg-9' : 'col-12'"
        >
            <div
                class="crop-image-container"
                :style="cropContainerStyle"
            >
                <img
                    ref="cropImage"
                    class="crop-image"
                    :src="fileBlob"
                >
            </div>
        </div>

        <!-- Cropper tools -->
        <div
            v-if="hasToolbar"
            class="col-lg-3 order-lg-1 d-flex flex-column mt-3 mt-lg-0"
        >
            <!-- Aspect ratio options -->
            <div
                v-if="ratios.length > 1"
                class="form-group"
            >
                <label v-text="$t('CROPPER.OPTION.ASPECT_RATIO')" />
                <div class="row no-gutters m-n1">
                    <div
                        v-if="freeRatio"
                        class="col-4 col-md-3 col-lg-6 p-1"
                    >
                        <btn
                            class="w-100"
                            label="Free"
                            @click="cropper && cropper.setAspectRatio(NaN)"
                        />
                    </div>
                    <div
                        v-for="(ratio, key) in ratiosLocal"
                        :key="key"
                        class="col-4 col-md-3 col-lg-6 p-1"
                    >
                        <btn
                            class="w-100"
                            :icon="['fac', key]"
                            :label="key"
                            @click="cropper && cropper.setAspectRatio(ratio.w / ratio.h)"
                        />
                    </div>
                </div>
            </div>

            <!-- Flip and rotate options -->
            <div
                v-if="flip || rotate"
                class="form-group"
            >
                <label>
                    <span
                        v-if="flip"
                        v-text="$t('CROPPER.OPTION.FLIP')"
                    />
                    <span
                        v-if="flip && rotate"
                        v-text="' & '"
                    />
                    <span
                        v-if="rotate"
                        v-text="$t('CROPPER.OPTION.ROTATE')"
                    />
                </label>
                <div>
                    <template v-if="flip">
                        <btn
                            :icon="['fac', 'flip-v']"
                            @click="flipImage('y')"
                        />
                        <btn
                            :icon="['fac', 'flip-h']"
                            @click="flipImage('x')"
                        />
                    </template>
                    <template v-if="rotate">
                        <btn
                            :icon="['fal', 'undo']"
                            @click="cropper && cropper.rotate(-90)"
                        />
                        <btn
                            :icon="['fal', 'redo']"
                            @click="cropper && cropper.rotate(90)"
                        />
                    </template>
                </div>
            </div>

            <!-- Local Actions -->
            <div
                v-if="localActions"
                class="form-group mb-0 mt-auto text-right text-lg-left"
            >
                <btn
                    :label="$t('CROPPER.ACTION.RESET')"
                    variant="primary"
                    emphasis="medium"
                    @click="reset"
                />
                <btn
                    :label="$t('CROPPER.ACTION.CROP')"
                    variant="primary"
                    @click="crop"
                />
            </div>
        </div>
    </div>
</template>

<script lang="ts">
    import Vue, { PropType, VueConstructor } from 'vue'
    import Cropper from 'cropperjs'
    import { base64ToFile, sanitizeFilename } from '@utils'

    interface Refs {
        $refs: {
            cropImage: HTMLImageElement;
        };
    }

    type ComputedRatios = {
        [key: string]: {
            w: number;
            h: number;
        };
    }

    export default (Vue as VueConstructor<Vue & Refs>).extend({
        props: {
            /**
             * The file to crop (required).
             * You can use the `.sync` modifier on this prop to sync
             * the value to the parent.
             */
            file: {
                type: File as PropType<File>,
                required: true,
            },
            /**
             * Available aspect ratios to set cropper to in the cropper toolbar.
             * You can add addition keyword `free` to allow free ratio contstraints.
             */
            ratios: {
                type: Array as PropType<string[]>,
                default: (): string[] => ['1:1', '16:9', '9:16', '4:3', '3:4', '3:2', '2:3'],
            },
            /**
             * Allow free ratio in combination with other set ratios.
             */
            freeRatio: {
                type: Boolean as PropType<boolean>,
                default: true,
            },
            /**
             * Allow flipping image on canvas horizontally and vertically
             */
            flip: {
                type: Boolean as PropType<boolean>,
                default: true,
            },
            /**
             * Allow allow rotating image on canvas
             */
            rotate: {
                type: Boolean as PropType<boolean>,
                default: true,
            },
            /**
             * Styles for the crop image container. This is normaly used to add some
             * height constraints to the container with `height` or `max-height`.
             * When contraining height, the cropper will fit the image inside and
             * generate a checkered pattern around the image.
             */
            cropContainerStyle: {
                type: [Object, Array, String] as PropType<object | any[] | string>,
                default: null,
            },

            /**
             * Render out local actions as part of the Option panel.
             * Note: By setting this to false you will need to access
             * these actions from component's public methods.
             * E.g `$refs.myCropper.crop()`
             */
            localActions: {
                type: Boolean as PropType<boolean>,
                default: true,
            },
        },

        data() {
            return {
                cropper: null as Cropper | null,
                scaleX: 1,
                scaleY: 1,
            }
        },

        computed: {
            fileBlob(): string {
                return URL.createObjectURL(this.file)
            },

            ratiosLocal(): ComputedRatios {
                return this.ratios.reduce((acc, item) => {
                    const [w, h] = item.split(':').map(Number)
                    acc[item] = { h, w }

                    return acc
                }, {} as ComputedRatios)
            },

            hasToolbar(): boolean {
                return (
                    this.ratios.length > 1 ||
                    this.flip ||
                    this.rotate ||
                    this.localActions
                )
            },
        },

        watch: {
            file: {
                handler(): void {
                    this.cropper?.destroy()
                    this.$nextTick(() => this.initCropper())
                },
                immediate: true,
            },
        },

        destroyed() {
            this.cropper?.destroy()
            this.scaleY = 1
            this.scaleX = 1
        },

        methods: {
            initCropper(): void {
                let aspectRatio = NaN
                if (!this.freeRatio) {
                    aspectRatio = this.ratios[0]
                        .split(':')
                        .map(Number)
                        .reduce((prev, curr) => prev / curr)
                }

                this.cropper = new Cropper(this.$refs.cropImage, {
                    viewMode: 2,
                    aspectRatio,
                    dragMode: 'move',
                    autoCropArea: 0.9,
                })
            },

            flipImage(axis: 'x' | 'y'): void {
                if (axis === 'x') {
                    this.scaleX = this.scaleX === 1 ? -1 : 1
                    this.cropper?.scaleX(this.scaleX)
                } else {
                    this.scaleY = this.scaleY === 1 ? -1 : 1
                    this.cropper?.scaleY(this.scaleY)
                }
            },

            /**
             * Crop current selection of cropper instance. You can pass
             * in some cropping options if needed.
             *
             * @see https://github.com/fengyuanchen/cropperjs#getcroppedcanvasoptions
             *
             * @param {Cropper.GetCroppedCanvasOptions} options Cropperjs Canvas options
             * @returns {File} The cropped image as File object.
             * @public
             */
            async crop(options?: Cropper.GetCroppedCanvasOptions): Promise<File> {
                if (!this.cropper)
                    throw new Error('Cannot crop image, cropper instance missing')

                const base64 = this.cropper
                    .getCroppedCanvas(options)
                    .toDataURL(this.file.type)

                const file = await base64ToFile(
                    base64,
                    sanitizeFilename(this.file.name),
                    this.file.type,
                )

                /**
                 * Triggers when cropping the image.
                 *
                 * @property {File} file The cropped image as File object.
                 */
                this.$emit('crop', file)
                this.$emit('update:file', file)

                return file
            },

            /**
             * Reset cropper to initial state.
             * @public
             */
            reset(): void {
                this.scaleY = 1
                this.scaleX = 1
                this.cropper?.reset()
            },
        },
    })
</script>

<style lang="scss" scoped>
    img.crop-image {
        max-width: 100%;
        display: block;
    }

</style>
