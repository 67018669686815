import { Location } from 'vue-router'
import { i18n } from '@plugins/i18n'
import { findParentKeys } from '@utils'
import { hasFeatures } from '@modules/Auth/utils'

export const CACHE_TIMEOUT = 5000 as const

export const getStatesMap = (): Record<InsightsState, { text: string; variant: string }> => ({
    completed: {
        text: String(i18n.t(`INSIGHTS.STATE.COMPLETED`)),
        variant: 'success',
    },
    completedAfterDeadline: {
        text: String(i18n.t(`INSIGHTS.STATE.COMPLETEDAFTERDEADLINE`)),
        variant: 'warning',
    },
    deadlineMissed: {
        text: String(i18n.t(`INSIGHTS.STATE.DEADLINEMISSED`)),
        variant: 'danger',
    },
    incomplete: {
        text: String(i18n.t(`INSIGHTS.STATE.INCOMPLETE`)),
        variant: 'danger',
    },
    notStarted: {
        text: String(i18n.t(`INSIGHTS.STATE.NOTSTARTED`)),
        variant: 'gray',
    },
    pending: {
        text: String(i18n.t(`INSIGHTS.STATE.PENDING`)),
        variant: 'info',
    },
    inProgress: {
        text: String(i18n.t(`INSIGHTS.STATE.INPROGRESS`)),
        variant: 'info',
    },
})

/**
 * Get variant and translation text for a insights state
 */
// eslint-disable-next-line vue/max-len
export const getStateVariant = (state: InsightsState): ReturnType<typeof getStatesMap>[keyof ReturnType<typeof getStatesMap>] => {
    const map = getStatesMap()

    return map[state] ?? {
        text: String(i18n.t('TERMS.UNKNOWN')),
        variant: 'gray-lighter',
    }
}

/**
 * Filter configurations
 */
export const getFiltersConfig = (): Record<keyof InsightsSchemaFilters, {
    type: (...args: any[]) => any;
    label: string;
    info: string;
}> => ({
    range: {
        type: Array,
        label: String(i18n.t('TERMS.DATE_RANGE')),
        info: String(''),
    },
    userIds: {
        type: Array,
        label: String(i18n.t('TERMS.USERS')),
        info: String(i18n.t('INSIGHTS.PARAM.USERS_INFO')),
    },
    divisionIds: {
        type: Array,
        label: String(i18n.t('FORMS.DIVISIONS')),
        info: String(i18n.t('INSIGHTS.PARAM.DIVISIONS_INFO')),
    },
    titleIds: {
        type: Array,
        label: String(i18n.t('STRUCTURE.JOBPROFILES.TITLE')),
        info: String(i18n.t('INSIGHTS.PARAM.TITLES_INFO')),
    },
    firstDayRange: {
        type: Array,
        label: String(i18n.t('INSIGHTS.PARAM.FIRST_DAY_RANGE')),
        info: String(i18n.t('INSIGHTS.PARAM.FIRST_DAY_RANGE_INFO')),
    },
    containerIds: {
        type: Array,
        label: String(i18n.tc('ACADEMY_MANAGEMENT.TITLE_PLURAL', 2)),
        info: String(i18n.t('INSIGHTS.PARAM.CONTAINERS_INFO')),
    },
    completion: {
        type: String,
        label: String(i18n.t('TERMS.COMPLETION')),
        info: String(i18n.t('INSIGHTS.PARAM.COMPLETION_INFO')),
    },
    userStatus: {
        type: String,
        label: String(i18n.t('TERMS.USER_STATUS')),
        info: String(i18n.t('INSIGHTS.PARAM.IS_ACTIVE_INFO')),
    },
    isPublic: {
        type: Boolean,
        label: String(i18n.t('TERMS.PART_OF')),
        info: String(i18n.t('INSIGHTS.PARAM.IS_PUBLIC_INFO')),
    },
    includeHistory: {
        type: Boolean,
        label: String(i18n.t('INSIGHTS.INCLUDE_HISTORY.TITLE')),
        info: String(i18n.t('INSIGHTS.PARAM.INCLUDE_HISTORY_INFO')),
    },
    states: {
        type: Array,
        label: String(i18n.t('TERMS.STATUS')),
        info: String(i18n.t('INSIGHTS.PARAM.INCLUDE_STATES_INFO')),
    },
    moduleIds: {
        type: Array,
        label: String(i18n.t('MODULE.MODULES')),
        info: String(i18n.t('INSIGHTS.PARAM.MODULES_INFO')),
    },
    moduleTypeIds: {
        type: Array,
        label: String(i18n.t('TERMS.MODULETYPE')),
        info: String(i18n.t('INSIGHTS.PARAM.MODULE_TYPES_INFO')),
    },
})

/**
 * Map of all available filters and their constructor type
 * for use in ParamHandler
 */
export const PARAM_TYPE_MAP = Object.entries(getFiltersConfig()).reduce((acc, [key, { type }]) => {
    acc[key as keyof InsightsSchemaFilters] = type

    return acc
}, {} as Record<keyof InsightsSchemaFilters, (...args: any[]) => any>)


/**
 * Map for which schema is allowed based on feature
 */
export const SCHEMA_ACL_MAP: Readonly<Record<InsightsSchemaGroupings, string>> = {
    container: 'InsightsContent',
    module: 'InsightsContent',
    user: 'InsightsLearners',
    userContainer: 'InsightsLearners',
    userModule: 'InsightsLearners',
    division: 'InsightsLearners',
    divisionTree: 'InsightsLearners',
    title: 'InsightsLearners',
}

/**
 * Route mapper to insights schema grouping
 */
export const ROUTE_MAPPER: Record<string, any> = {
    content: {
        'learning-paths': {
            index: 'container',
        },
        'modules': {
            index: 'module',
        },
    },
    learners: {
        'users': {
            'index': 'user',
            'learning-paths': 'userContainer',
            'modules': 'userModule',
        },
        'departments': {
            index: 'division',
            parent: 'divisionTree',
        },
        'job-profiles': {
            index: 'title',
        },

    },
}

/**
 * Get vue-router location object for insights page by schema grouping type
 *
 * @param grouping Valid schema group
 * @param query Additional query parameters
 */
export function getSchemaLocation(
    grouping: InsightsSchemaGroupings,
    query?: Partial<Record<keyof InsightsSchemaFilters, any>>,
): Location | void {
    const canSchema = hasFeatures(SCHEMA_ACL_MAP[grouping])

    if (!canSchema)
        return

    const [type, scope, group] = findParentKeys(ROUTE_MAPPER, grouping) ?? []

    return {
        name: 'insights.index',
        params: {
            type,
            scope,
            ...(group && group !== 'index') && { group },
        },
        query,
    }
}