import { AxiosError } from 'axios'

import store from '@store'
import router from '@router'
import { i18n } from '@plugins/i18n'
import { dialog } from '@plugins/dialog'

const SessionCheckInModal = import('@modules/Courses/components/Session/SessionCheckInModal.vue')
const SessionTicketModal = import('@modules/Courses/components/Session/SessionTicketModal.vue')

const REGISTRATION_ERROR_CODES = new Set([
    'CHECK_IN_TOO_EARLY',
    'CHECK_IN_CLOSED',
    'CHECK_IN_NOT_FOUND',
])

export const MARK_ATTENDANCE_TYPE = {
    AUTO: 'auto',
    ADMIN: 'admin',
    USER: 'user',
} as const

async function showAlert(code: string) {
    if (!REGISTRATION_ERROR_CODES.has(code))
        return

    router.replace({ query: undefined })

    await dialog.alert({
        size: 'sm',
        title: i18n.t(`COURSE.USER.REGISTRATION.ERROR.${code}.TITLE`),
        message: i18n.t(`COURSE.USER.REGISTRATION.ERROR.${code}.DESCRIPTION`),
    })
}


export async function handleCheckIn(courseId: number, registrationCode?: string | null): Promise<void> {
    if (!registrationCode)
        return

    let data
    try {
        data = await store.dispatch('Courses/Registration/getAvailableSessions', { courseId })
    } catch (e) {
        const { response } = e as AxiosError
        const code = response?.data?.code

        await showAlert(code)

        throw e
    }

    if (data.length === 0)
        return showAlert('CHECK_IN_NOT_FOUND')


    const selected = data.length === 1
        ? data[0]
        : await dialog.modal(SessionCheckInModal, { value: data })

    const result = await store.dispatch('Courses/Registration/postCheckIn', {
        courseId,
        sessionId: selected.id,
        registrationCode,
    })

    router.replace({ query: undefined })

    await dialog.modal(SessionTicketModal, { value: result })
}
