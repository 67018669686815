<template>
    <div class="d-flex">
        <div class="d-flex align-items-center justify-content-center openai--content-violation__icon mr-2">
            <fa
                class="text-warning"
                size="2x"
                :icon="['fas', 'exclamation-triangle']"
            />
        </div>

        <div>
            <strong
                v-if="!noTitle"
                v-t="'OPENAI.CONTENT_POLICY.VIOLATION.TITLE'"
                class="mb-3"
            />
            <i18n
                tag="p"
                path="OPENAI.CONTENT_POLICY.VIOLATION.DESCRIPTION"
            >
                <template #policy>
                    <a
                        v-t="'TERMS.USAGE_POLICIES'"
                        class="text-lowercase"
                        href="https://help.learningbank.io/en/ai-usage-policies-guidelines-for-safe-and-responsible-use"
                        target="_blank"
                    />
                </template>
            </i18n>
            <i18n
                tag="p"
                path="OPENAI.CONTENT_POLICY.VIOLATION.WARNING"
            >
                <template #policy>
                    <a
                        v-t="'TERMS.USAGE_POLICIES'"
                        class="text-lowercase"
                        href="https://help.learningbank.io/en/ai-usage-policies-guidelines-for-safe-and-responsible-use"
                        target="_blank"
                    />
                </template>
            </i18n>
        </div>
    </div>
</template>

<script lang="ts">
    import Vue, { PropType } from 'vue'

    export default Vue.extend({
        props: {
            noTitle: {
                type: Boolean as PropType<boolean>,
                default: false,
            },
        },
    })
</script>

<style lang="scss" scoped>
    .openai--content-violation__icon {
        height: 3rem;
        flex: 0 0 3rem;
    }
</style>
