import Vue from 'vue'
import Vuex from 'vuex'
import createCache from 'vuex-cache'
import pathify from 'vuex-pathify'
import moduleLoaderPlugin from './plugins/module-loader'
import { $localStorage } from '@plugins/storage'

/**
 * Static modules
 * These modules are available at all times, no need to load them in manually.
 */
import Auth from '@modules/Auth/store'
import Resource from './resource.store'
import AppConfig from './app-config.store'
import Image from './image.store'
import Whitelabels from '@modules/Whitelabels/store'

Vue.use(Vuex)

/**
 * Initialize a vuex instance
 */
const store = new Vuex.Store({
    strict: true,
    plugins: [
        createCache({ timeout: 30000 }),
        pathify.plugin,
        moduleLoaderPlugin,
    ],

    modules: {
        Resource,
        Auth,
        Whitelabels,
        AppConfig,
        Image,
    },

    actions: {
        /**
         * Initial setup when app boots the first time
         */
        initApp({ commit, dispatch, rootState }): void {
            const url = new URL(window.location.href)
            // Set localStorage property if in webview
            if (url.searchParams.has('webview')) {
                $localStorage.set('webview', true)
            }
            // Get rid of login_token param which is used to sign in as
            // support or other types of SSO logins via frontend-service
            if (history.replaceState && url.searchParams.has('login_token')) {
                url.searchParams.delete('login_token')
                history.replaceState({}, '', url.toString())
            }
            // Init Whitelabel configuration
            commit('Whitelabels/SYNC_CURRENT')
            // Set document title
            document.title = rootState.AppConfig.appConfig.white_label_name
            // Check for authentication
            dispatch('Auth/check')
            // Set platform language
            commit('AppConfig/SET_LANG')
            // Load custom fonts
            dispatch('AppConfig/loadFonts')
        },
    },
})

export default store
