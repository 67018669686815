<template>
    <BBadge
        v-if="status"
        :variant="status.variant"
        v-text="$t(status.label)"
    />
</template>

<script setup lang="ts">
    import { computed } from 'vue'
    import { BBadge } from 'bootstrap-vue'
    import { getUserStatus } from '../../utils'

    interface Props {
        value: SessionAttentionModel | null
    }

    const props = defineProps<Props>()

    const status = computed((): SessionUserStatusDetails | null => getUserStatus(props.value))
</script>
