import Vue from 'vue'
import DataTable from '@common/DataTable/DataTable.vue'
import DataTableTree from '@common/DataTable/DataTableTree.vue'
import DataTableRemote from '@common/DataTable/DataTableRemote.vue'
import TableColumn from '@common/DataTable/TableColumn'
import Topbar from '@common/Topbar/Topbar.vue'
import TopbarNavItem from '@common/Topbar/TopbarNavItem.vue'
import TopbarAction from '@common/Topbar/TopbarAction'
import ButtonBase from '@common/Buttons/ButtonBase.vue'
import MarkQuery from '@common/components/MarkQuery.vue'
import { FontAwesomeIcon, FontAwesomeLayers, FontAwesomeLayersText } from '@fortawesome/vue-fontawesome'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import InlineSvg from 'vue-inline-svg'
import PageLayout from '@common/Page/PageLayout.vue'
import PageTitle from '@common/Page/PageTitle.vue'

Vue.component('data-table', DataTable)
Vue.component('data-table-tree', DataTableTree)
Vue.component('data-table-remote', DataTableRemote)
Vue.component('table-column', TableColumn)
Vue.component('topbar', Topbar)
Vue.component('topbar-action', TopbarAction)
Vue.component('topbar-nav-item', TopbarNavItem)
Vue.component('mark-query', MarkQuery)
Vue.component('fa', FontAwesomeIcon)
Vue.component('fa-layers', FontAwesomeLayers)
Vue.component('fa-text', FontAwesomeLayersText)
Vue.component('validation-provider', ValidationProvider)
Vue.component('validation-observer', ValidationObserver)
Vue.component('inline-svg', InlineSvg)
Vue.component('btn', ButtonBase)
Vue.component('page-layout', PageLayout)
Vue.component('page-title', PageTitle)

