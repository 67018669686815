import Vue, { PropType } from 'vue'

export const DropdownPropsMixin = Vue.extend({
    props: {
        /**
         * Allow multiple selection
         */
        multiple: {
            type: Boolean as PropType<boolean>,
            default: false,
        },

        /**
         * Limit on how many elements can be selected
         * Requires `multiple` to be `true`
         */
        multipleLimit: {
            type: Number as PropType<number>,
            default: null,
        },

        /**
         * Display block for b-dropdown component
         */
        block: {
            type: Boolean as PropType<boolean>,
            default: false,
        },
        /**
         * Allow tag creation
         */
        taggable: {
            type: Boolean as PropType<boolean>,
            default: false,
        },
        /**
         * Align dropdown content to the right of the button
         */
        right: {
            type: Boolean as PropType<boolean>,
            default: false,
        },
        /**
         * Variant name for toggle button
         */
        variant: {
            type: String as PropType<string>,
            default: 'default',
        },
        /**
         * Field name in the `options` prop array that should be
         * used for the label when option is an object
         * - You can use dot.notation to use nested properties in object
         *   via the lodash `get` utility
         * - Alternatively you can provide a callback that give you the
         *   option in question and you can do any types of transforms to it.
         */
        textField: {
            type: [String, Function] as PropType<string | ((value: any) => string)>,
            default: 'text',
        },
        /**
         * Field in the options prop that should be used as value for
         * the v-model. If left empty the whole option object will be used
         */
        valueField: {
            type: String as PropType<string>,
            default: null,
        },
        /**
         * Field in the options array that is used internally
         * to track the set value to it's option.
         * **This value needs to be unique!**
         */
        trackBy: {
            type: String as PropType<string>,
            default: 'id',
        },
        /**
         * Enables search with in options
         * Accepts boolean to search in all properties of options
         * or an array of properties to search with in.
         */
        searchable: {
            type: [Boolean, Array] as PropType<boolean | string[]>,
            default: false,
        },
        /**
         * Weather search is applied on options directly, if set to false we only
         * fire `search-change` event with the query. Useful to handle search
         * outside of component.
         */
        internalSearch: {
            type: Boolean as PropType<boolean>,
            default: true,
        },
        /**
         * Allows to remove all selected values. Otherwise one must be left selected.
         */
        allowEmpty: {
            type: Boolean as PropType<boolean>,
            default: true,
        },

        /**
         * Close dropdown when you click clear
         */
        closeOnClear: {
            type: Boolean as PropType<boolean>,
            default: false,
        },

        /**
         * Clear search when option is selected
         */
        clearOnSelect: {
            type: Boolean as PropType<boolean>,
            default: true,
        },

        /**
         * Apply selection when dropdown is closed
         */
        applyOnClose: {
            type: Boolean as PropType<boolean>,
            default: true,
        },

        /**
         * Unselect selection when dropdown is closed
         */
        resetOnClose: {
            type: Boolean as PropType<boolean>,
            default: false,
        },

        /**
         * Display button and badge values as rounded
         * */
        pill: {
            type: Boolean as PropType<boolean>,
            default: false,
        },
        /**
         * Display a caret in the button content default.
         * This will be overwritten if using `button-content` slot
         */
        caret: {
            type: Boolean as PropType<boolean>,
            default: false,
        },
        /**
         * Classes for the dropdown toggle button
         * */
        toggleClass: {
            type: [Object, String, Array] as PropType<object | string | any[]>,
            default: (): object => ({}),
        },
        /**
         * This prop takes a function with one parameter, the current value
         * of selection, This is only visible when a value has been selected.
         * `button-content` slot will overwrite this.
         */
        buttonContentLabel: {
            type: Function as PropType<(value: any) => string>,
            default: null,
        },

        /**
         * Maximum height of the dropdown menu. Accepts any css unit as string
         */
        menuMaxHeight: {
            type: String as PropType<string>,
            default: '65vh',
        },

        /**
         * Minimum width of the drop down menu. Accepts any css unit as string
         */
        menuMinWidth: {
            type: String as PropType<string>,
            default: '18rem',
        },

        /**
         * Apply custom class to the select box
         */
        dropdownClass: {
            type: String as PropType<string>,
            default: '',
        },

        /**
         * Fixes the placeholder before displaying selected value
         */
        placeholderFixed: {
            type: Boolean as PropType<boolean>,
            default: false,
        },

        boundary: {
            type: String as PropType<string>,
            default: null,
        },
    },
})
