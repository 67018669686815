import { i18n } from '@plugins/i18n'

function getConfig(payload?: Partial<KnowbotChatConfigSettings>): KnowbotChatConfigSettings {
    const defaultConfig = {
        customBotName: 'Knowbot',
        hideHeaderLogo: true,
        fullChatbotLogo: true,
        disableSharing: true,
        temperature: 1,
        customWelcomeText: i18n.t('KNOWBOT.WELCOME.TITLE') as string,
        introMessage: i18n.t('KNOWBOT.WELCOME.SUBTITLE') as string,
        inputPlaceholder: i18n.t('KNOWBOT.INPUTTEXT') as string,
        openLinks: 'newTab',
        backgroundColor: '#edf4fc',
        customAvatarImageUrl: null,
        customBotAvatarImageUrl: 'https://drs8l3cc0nrnc.cloudfront.net/assets/KnowbotLogo.svg',
        customSourcesText: i18n.t('KNOWBOT.SOURCE.TEXT') as string,
        font: 'Noto Sans',
        showAudioInput: true,
        showAudioOutput: true,
        voice: 'en-US-BrianMultilingualNeural',
    }

    return Object.assign(defaultConfig, payload)
}


export function getInit(payload: KnowbotChatConfigPayload): KnowbotChatConfig {
    return {
        type: 'init',
        settings: getConfig(payload?.settings),
        groupIds: [
            // We need to send 'null' as a string to give access to all public resources - this is how Applai expects it
            'NULL',
            ...payload.groupIds || [],
        ],
    }
}
