<template>
    <Card
        class="course-card pointer"
        :class="{
            'card-hover pointer': clickable,
        }"
        border="none"
        :body-class="{ 'pt-1 pb-0': !!horizontalLocal }"
        :footer-class="{
            'pb-1': !!horizontalLocal,
            'd-flex align-items-center justify-content-between': true
        }"
        :horizontal="horizontalLocal"
        @click="onClick"
    >
        <template #before>
            <div class="position-relative">
                <image-lazy
                    :src="value.thumbnailPath"
                    :ar="'16:9'"
                    :class="[horizontalLocal ? 'rounded-left' : 'rounded-top']"
                    :no-lazy="noLazyImg"
                    fill-blur
                >
                    <template #fallback>
                        <div
                            class="embed-responsive embed-responsive-16by9 bg-light text-gray-dark"
                            :class="[ horizontalLocal ? 'rounded-left' : 'rounded-top' ]"
                        >
                            <inline-svg
                                class="centerer w-35"
                                :src="require('@assets/icons/icon-calendar.svg')"
                            />
                        </div>
                    </template>
                </image-lazy>

                <div class="position-absolute bottom-0 w-100 d-flex align-items-end g-2">
                    <CalendarIcon
                        :style="!horizontalLocal ? 'transform: translateY(0.5rem)' : null"
                        :class="{
                            'ml-3': !horizontalLocal
                        }"
                        :date="startDate"
                        :variant="userStatus?.variant || 'gray'"
                    />

                    <CourseLearnerStatusBadge
                        :value="context.highlighted"
                        class="ml-auto rounded-0 rounded-top-left"
                    />
                </div>
            </div>
        </template>
        <template #default>
            <small
                class="text-muted"
                v-text="$t('TERMS.COURSE')"
            />
            <h6
                v-line-clamp:[lineClampArg]="2"
                class="font-weight-bold mb-0"
                v-text="value.name"
            />

            <SessionDatesDisplay
                v-if="context.highlighted"
                :value="context.highlighted"
                format-day="ll"
                class="text-muted"
            />
        </template>

        <template #footer>
            <small
                v-if="value.sessionCount && !isExplorable"
                class="text-muted"
                v-text="$tc('SESSION.COUNT', value.sessionCount)"
            />
            <small
                class="text-muted"
                v-text="$t('TERMS.SEE_ALL')"
            />
        </template>
    </Card>
</template>

<script lang="ts">
    import Vue, { PropType } from 'vue'

    import Card, { HorizontalOptions } from '@common/Card/Card.vue'
    import CalendarIcon from '@common/components/CalendarIcon.vue'
    import ImageLazy from '@common/Images/ImageLazy.vue'

    import SessionDatesDisplay from './SessionDatesDisplay.vue'

    import { getStartDate, getUserStatus } from '../utils'
    import CourseLearnerStatusBadge from './Learner/CourseLearnerStatusBadge.vue'


    export default Vue.extend({
        components: {
            CourseLearnerStatusBadge,
            Card,
            CalendarIcon,
            ImageLazy,
            SessionDatesDisplay,
        },

        props: {
            value: {
                type: Object as PropType<CourseDataModel>,
                required: true,
            },

            context: {
                type: Object as PropType<CourseContextModel>,
                default: null,
            },

            noLazyImg: {
                type: Boolean as PropType<boolean>,
                default: false,
            },

            horizontal: {
                type: [Boolean, Object] as PropType<boolean | HorizontalOptions>,
                default: false,
            },

            clickable: {
                type: Boolean as PropType<boolean>,
                default: true,
            },

            /**
             * Learning content explorable check
             */
            isExplorable: {
                type: Boolean as PropType<boolean>,
                default: false,
            },
        },

        computed: {
            lineClampArg(): string | null {
                return this.$device.isDesktop ? 'fixed' : null
            },

            horizontalLocal(): boolean | HorizontalOptions {
                if (typeof this.horizontal === 'object')
                    return this.horizontal

                return this.horizontal
                    ? {
                        before: 'col-5',
                        row: 'align-items-center',
                    }
                    : false
            },

            startDate(): string | null {
                if (!this.context.highlighted)
                    return null

                return getStartDate(this.context.highlighted, 'YYYY-MM-DD HH:mm:ss')
            },

            userStatus(): SessionUserStatusDetails | null {
                return getUserStatus(this.context.highlighted)
            },
        },

        methods: {
            onClick(): void {
                if (!this.clickable)
                    return

                this.$router.push({
                    name: 'learning.events.details',
                    params: {
                        courseId: this.value.id.toString(),
                    },
                })
            },
        },
    })
</script>
