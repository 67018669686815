<template>
    <div
        ref="bottomSheet"
        class="bottom-sheet position-fixed absolute-fill $zindex-modal d-flex flex-column align-items-center justify-content-end"
        :class="{ 'bottom-sheet--visible': isVisible, 'justify-content-center': isModal }"
    >
        <div
            class="embed-responsive-item--backdrop absolute-fill"
            @click="hideSheet"
        />

        <div
            class="bottom-sheet__container w-100 bg-off-white overflow-hidden"
            :class="{
                'bottom-sheet__container--visible pb-5 ': isVisible,
                'modal-container rounded-xl': isModal,
                'vh-100': fullscreen && !isModal,
            }"
            :style="`background-color: ${backgroundColor} !important`"
        >
            <div
                v-if="showCloseButton"
                class="d-flex justify-content-end p-3"
            >
                <button

                    class="close-btn pointer"
                    @click="hideSheet"
                >
                    <fa
                        :icon="['fal', 'close']"
                    />
                </button>
            </div>
            <div class="h-100">
                <slot />
            </div>
        </div>
    </div>
</template>

<script lang="ts">
    import Vue, { PropType } from 'vue'

    export default Vue.extend({
        props: {
            fullscreen: {
                type: Boolean as PropType<boolean>,
                default: false,
            },
            showCloseButton: {
                type: Boolean as PropType<boolean>,
                default: false,
            },
            mode: {
                type: String as PropType<'modal' | 'sheet'>,
                default: 'sheet',
            },

            backgroundColor: {
                type: String,
                default: '#fff',
            },
        },

        data() {
            return {
                isVisible: false,
            }
        },

        computed: {
            isModal(): boolean {
                return this.mode === 'modal'
            },
        },

        methods: {
            showSheet() {
                this.isVisible = true
                document.body.classList.add('overflow-hidden')
            },
            hideSheet() {
                this.isVisible = false
                this.$emit('close')
                document.body.classList.remove('overflow-hidden')
            },
        },
    })
</script>

<style lang="scss" scoped>
@import '@scss/vue.scss';

.bottom-sheet {
  z-index: $zindex-modal;
  opacity: 0;
  visibility: hidden;
  transition: opacity 300ms ease-in-out, visibility 300ms;

  &--visible {
    opacity: 1;
    visibility: visible;
  }

  &__overlay {
    background: $modal-backdrop-opacity;
  }

  &__container {
    min-height: 75vh;
    transform: translateY(100%);
    transition: transform 300ms ease-in-out;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;

    &--visible {
      transform: translateY(0);
    }
  }
}

.modal-container {
  max-height: 90vh;
  max-width: $modal-md;
}

.close-btn {
  border: none;
  background: none;
  font-size: $font-size-lg;
}
</style>
