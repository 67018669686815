<script lang="ts">
    import Vue, { PropType } from 'vue'
    import { mergeData } from 'vue-functional-data-merge'
    import { BButton } from 'bootstrap-vue'
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
    import { Location } from 'vue-router'

    const computeVariant = ({ variant, emphasis }: any): string => {
        if (emphasis === 'low')
            return `simple-` + variant
        else if (emphasis === 'medium')
            return `outline-` + variant
        else
            return variant
    }


    const computeClasses = (props: any, hasDefaultSlot: boolean): object => ({
        'btn-icon': props.icon && !props.label && !hasDefaultSlot,
    })

    /**
     * A Basic button component, extanding bootsrap-vue's `BButton` component
     *
     * **Alias:**  Globally imported as `<btn />`
     *
     * This is our basic button, it has several props to determine how it should visually be displayed.
     * Take a look at the examples to see different variations of this button
     *
     * This button is a **functional component** and is not intended for specific usecase,
     * If you need a specific usecase then build another button that imports this
     *
     * @example ./ButtonBase.examples.md
     */
    export default Vue.extend({
        name: 'ButtonBase',
        functional: true,
        props: {
            /** When set to `true`, places the component
             * in the active state with active styling
             */
            active: {
                type: Boolean as PropType<boolean>,
                default: null,
            },

            /**
             * Display button as block, fills its container
             */
            block: {
                type: Boolean as PropType<boolean>,
                default: false,
            },

            /** Toggle disabled state */
            disabled: {
                type: Boolean as PropType<boolean>,
                default: false,
            },

            /** Determinse the weigth of the button */
            emphasis: {
                type: String as PropType<string>,
                default: 'high',
                validator: (value: string): boolean => [
                    'high',
                    'medium',
                    'low',
                ].includes(value),
            },

            /**
             * Converts button to anchor tag and sets the href
             */
            href: {
                type: String as PropType<string>,
                default: null,
            },

            /**
             * Use with `href` prop
             */
            target: {
                type: String as PropType<string>,
                default: '_self',
            },

            /**
             * Prepended icon in button. Takes the same value
             * as our font awesome component
             */
            icon: {
                type: [Array, String] as PropType<string[] | string>,
                default: null,
            },

            /**
             * Apply class to icon component
             */
            iconClass: {
                type: String as PropType<string>,
                default: null,
            },

            /** Place icon on the right side of label */
            iconRight: {
                type: Boolean as PropType<boolean>,
                default: false,
            },

            /** Button text */
            label: {
                type: String as PropType<string>,
                default: null,
            },

            /** Rounded pill */
            pill: {
                type: Boolean as PropType<boolean>,
                default: false,
            },

            /** Button size. */
            size: {
                type: String as PropType<string>,
                default: 'md',
                validator: (value: string): boolean => ['sm', 'md', 'lg'].includes(value),
            },

            /** Specify the HTML tag to render instead of the default tag */
            tag: {
                type: String as PropType<string>,
                default: 'button',
            },

            /**
             * <router-link> prop: Denotes the target route of the link. When clicked,
             * the value of the to prop will be passed to `router.push()` internally,
             * so the value can be either a string or a Location descriptor object
             */
            to: {
                type: [String, Object] as PropType<string | Location>,
                default: null,
            },

            /** Buttons color variants */
            variant: {
                type: String as PropType<string>,
                default: 'default',
                validator: (value: string): boolean => [
                    'default',
                    'primary',
                    'secondary',
                    'accent',
                    'success',
                    'danger',
                    'warning',
                    'link',
                    'white',
                    'light',
                    'dark',
                    'info',
                    'neutral-5',
                ].includes(value),
            },
        },

        render(h, { props, data, children }) {
            const defaults = []

            // Add icon if its set
            if (props.icon) {
                defaults.push(h(FontAwesomeIcon, {
                    staticClass: props.iconClass,
                    class: {
                        'mr-1': !!props.label && !props.iconRight,
                        'ml-1': !!props.label && props.iconRight,
                    },
                    props: {
                        icon: props.icon,
                        fixedWidth: true,
                    },
                }))
            }

            // Add button label if prop is set
            // Wrap label inside a span if icon is present. (For better css selection)
            if (props.label) {
                const label = props.icon
                    ? h('span', props.label)
                    : props.label

                defaults.push(label)
            }

            // Reverse order of defaults to have icon render on the right
            if (props.iconRight) {
                defaults.reverse()
            }

            const componentData = {
                class: computeClasses(props, !!children),
                props: {
                    ...props,
                    variant: computeVariant(props),
                    pressed: props.active,
                },
            }

            /**
             * @slot Default slot.
             * Will overwrite `label` and `icon` prop and display
             * anything in slot as button content
             */
            return h(
                BButton,
                mergeData(data, componentData),
                children ?? defaults,
            )
        },

    })
</script>

<style lang="scss">
    @import '@scss/vue.scss';

    // Place buttons inside a btn-container to get
    // correct spacing between buttons.
    .btn-container {
        .btn + .btn {
            margin-left: spacer(2);
        }
    }


    .btn-icon, .btn-img {
        $size: calc-btn-size($input-btn-font-size, $input-btn-line-height, $input-btn-padding-y);
        position: relative;
        width: $size;
        height: $size;
        line-height: $size;
        text-align: center;
        /* stylelint-disable-next-line declaration-no-important */
        padding: 0 !important;

        .svg-inline--fa:not(.fa-spin),
        .fa:not(.fa-spin) {
            @include centerer();
        }

        > img {
            aspect-ratio: 1 / 1;
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: $btn-border-radius;
            @at-root .btn-img {
                border: 0 !important;
            }
        }

        &.btn-sm {
            $size: calc-btn-size($input-btn-font-size-sm, $input-btn-line-height-sm, $input-btn-padding-y-sm);
            width: $size;
            height: $size;
            line-height: $size;
        }

        &.btn-lg {
            $size: calc-btn-size($input-btn-font-size-lg, $input-btn-line-height-lg, $input-btn-padding-y-lg);
            width: $size;
            height: $size;
            line-height: $size;
        }
    }

</style>
