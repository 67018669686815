import Vue, { VNode } from 'vue'
import { DirectiveBinding } from 'vue/types/options'

import store from '@store'

Vue.directive('knowbot', {
    bind: (el: HTMLElement, _bindings: DirectiveBinding, vnode: VNode): void => {
        (el as any).__handler = (): void  => {
            const knowbot = store.get('Knowbot/knowbot')

            if (!knowbot?.externalId) {
                throw new Error('[v-knowbot]: Knowbot is not configured yet.')
            }

            (vnode.context as Vue).$root.$emit('open-knowbot')
        }

        el.addEventListener('click', (el as any).__handler)
    },
    unbind(el: any) {
        el.removeEventListener('click', el.__handler)
    },
})
