/**
 * Helper function to decode html entities from a string into their
 * actual symbols using a textarea element
 *
 * @param string
 */
function decodeHtmlEntities(string: string) {
    const txt = document.createElement('textarea')
    txt.innerHTML = string

    return txt.value
}

/**
 * Strip html tags from string
 *
 * @param string
 * @param preserveLineBreaks preserve line breaks (\n) on block elements
 */
export const stripTags = (string: string, preserveLineBreaks = false): string => {
    if (preserveLineBreaks) {
        string = string
            // Preserve line breaks for block elements
            .replace(/(<\/(div|li|ul|ol|h[1-6])[^>]*>)/gi, '\n')
            // Preserve line break for <br> tags
            .replace(/<br>/gi, '\n')
            // Preserve two line breaks for </p> tags
            .replace(/<\/p>/gi, '\n\n')
    }

    return decodeHtmlEntities(string.replace(/(<([^>]+)>)/gi, ''))
}

/**
 * Convert string to snakecase
 * @param string
 */
export function snakeCase(string: string): string {
    return string
        .replace(/\W+/g, ' ')
        .split(/ |\B(?=[A-Z])/)
        .map((word) => word.toLowerCase())
        .join('_')
}

/**
 * Generates a unique string
 *
 * @param length length of string to be generated
 */
export function getUniqueString(length = 16): string {
    return [...Array(length)]
        .map(() => (~~(Math.random() * 36)).toString(36))
        .join('')
}

/**
 * Get initials from a string, maximum 2 characters
 * blatantly borrowed from Stackoverflow
 *
 * @see https://stackoverflow.com/a/63763497
 *
 * @param value string
 * @returns string
 */
export function getInitials(value: string): string {
    return value.match(/(^\S\S?|\s\S)?/g)
        ?.map((v) => v.trim())
        .join('')
        .match(/(^\S|\S$)?/g)
        ?.join('')
        .toLocaleUpperCase() ?? ''
}

/**
 * Replaces placeholders defined with colon prefix.
 * - if match isn't found we fallback to original substring
 * - Placeholders are case-sensitive
 *
 * - Usage:
 *      replacePlaceholders('api2/foo/:fooId/bar/:barId', { fooId: 10, barid: 12 })
 *      output => 'api2/foo/10/bar/:barId'
 */
export function replacePlaceholders(value: string, vars: Record<string, any>): string {
    return value.replace(/:\w+/g, (match) => vars[match.slice(1)] || match)
}

/**
 * Extract placeholders defined with colon prefix
 * - returns placeholder names without color prefix as string[]
 * - returned placeholders are unique
 *
 * - Usage:
 *      extractPlaceholders('api2/foo/:fooId/bar/:barId/:fooId')
 *      output => ['fooId', 'barId']
 */
export function extractPlaceholders(value: string): string[] {
    const matches = value.match(/:\w+/g) || []
    const uniqueNames = new Set(matches.map((match) => match.slice(1)))

    return Array.from(uniqueNames)
}

export function lineBreakToHtml(value: string): string {
    return value.replace(/(?:\r\n|\r|\n)/g, '<br>')
}

export function capitalize(value: string): string {
    if (!value)
        return ''

    return value.charAt(0).toUpperCase() + value.slice(1)
}

export function lowercase(value: string): string {
    if (!value)
        return ''

    return value.toLowerCase()
}

export function uppercase(value: string): string {
    if (!value)
        return ''

    return value.toUpperCase()
}

export function truncate(value: string, limit = 80, clamp = '...'): string {
    if (value?.length <= limit)
        return value

    const subtract = limit - clamp.length

    return value.substring(0, subtract) + clamp
}

export function truncateWords(value: string, limit = 5, delimiter = ' '): string {
    const words = value.split(delimiter) || []

    if (words?.length <= limit)
        return value

    return words.slice(0, limit).join(delimiter) + '...'
}

/**
 * Calculates the aspect ratio of given width and height.
 *
 * @param width - The width of the rectangle (must be positive).
 * @param height - The height of the rectangle (must be positive).
 * @param format - Specifies the return format; can be String, Array, or Object.
 * @returns The aspect ratio in the specified format.
 * @throws Error if width or height is zero or negative.
 */
export function getAspectRatio(w: number, h: number): string
export function getAspectRatio(w: number, h: number, format: ArrayConstructor): [number, number]
export function getAspectRatio(w: number, h: number, format: ObjectConstructor): { width: number; height: number }
export function getAspectRatio(w: number, h: number, format: StringConstructor): string
export function getAspectRatio(
    w: number,
    h: number,
    format: StringConstructor | ArrayConstructor | ObjectConstructor = String,
): string | [number, number] | { width: number; height: number } {
    if (w <= 0 || h <= 0)
        throw new Error('Width and height must be positive numbers.')

    const gcd = (a: number, b: number): number => (b === 0 ? a : gcd(b, a % b))
    const divisor = gcd(w, h)

    const gcdWidth = w / divisor
    const gcdHeight = h / divisor

    // Define a mapping object for output formats
    const formatMap: Record<string, any> = {
        [String.name]: () => `${gcdWidth}:${gcdHeight}`,
        [Array.name]: () => [gcdWidth, gcdHeight],
        [Object.name]: () => ({ width: gcdWidth, height: gcdHeight }),
    }

    // Call the appropriate function based on the format, default to String
    return (formatMap[format.name] || formatMap[String.name])()
}