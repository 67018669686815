import routesFactory from '@router/routes-factory'
import { auth, hasFeatures } from '@middleware'


const routerConfig = {
    baseURI: 'dashboard',
    module: 'Dashboards',
    middleware: [
        auth(['admin', 'client']),
    ],
}

export default routesFactory(routerConfig, [
    {
        path: '',
        name: 'dashboard',
        props: {
            mode: 'DEFAULT',
        },
        component: (): Promise<any> =>
            import(
                /* webpackChunkName: "modules/Dashboards" */
                './DashboardIndex.vue'
            ),
    },
    {
        path: 'manage',
        name: 'dashboard.manage',
        meta: {
            middleware: [
                auth('client', { name: '401' }),
                hasFeatures('Organization', { name: '404' }, 'admin'),
            ],
        },
        component: (): Promise<any> =>
            import(
                /* webpackChunkName: "modules/Dashboards" */
                './DashboardManageIndex.vue'
            ),
    },
    {
        path: 'default',
        name: 'dashboard.default',
        component: (): Promise<any> =>
            import(
                /* webpackChunkName: "modules/Dashboards" */
                './DashboardManageIndex.vue'
            ),
    },
    {
        path: 'preview',
        name: 'dashboard.preview',
        props: {
            mode: 'PREVIEW',
        },
        component: (): Promise<any> =>
            import(
                /* webpackChunkName: "modules/Dashboards" */
                './DashboardIndex.vue'
            ),
    },
])
