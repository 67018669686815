<template>
    <div
        class="alert-modal"
        tabindex="-1"
        role="dialog"
        @mousedown.stop
    >
        <div
            :class="modalClass"
            class="modal-dialog modal-dialog-centered"
            role="document"
            @mousedown.stop
            @mouseup.stop
        >
            <div class="modal-content">
                <div
                    v-if="options.title"
                    class="modal-header"
                >
                    <h5
                        class="modal-title"
                        v-html="options.title"
                    />
                </div>
                <div class="modal-body">
                    <h5
                        v-if="!options.title"
                        class="modal-title alert-modal__html-content"
                        v-html="options.message"
                    />
                    <div
                        v-else
                        class="alert-modal__html-content"
                        v-html="options.message"
                    />
                    <b-form-input
                        v-if="options.confirmInput"
                        v-model="internalConfirm"
                        class="mt-2"
                        type="text"
                    />
                </div>
                <div class="modal-footer">
                    <!-- Dismiss forever checkbox -->
                    <b-form-checkbox
                        v-if="options.storageKey"
                        v-model="storageEnabled"
                        class="mr-auto"
                    >
                        {{ $t('TIPS.HIDE_MSG') }}
                    </b-form-checkbox>
                    <!-- Cancel button -->
                    <btn
                        v-if="type === 'CONFIRM'"
                        variant="default"
                        emphasis="low"
                        :label="options.cancel || $t('TERMS.CANCEL')"
                        @click="$error(false)"
                    />
                    <!-- Ok button -->
                    <btn
                        :variant="options.okVariant || 'primary'"
                        :label="options.ok || $t('TERMS.OK_GOT_IT')"
                        :icon="okIcon"
                        :disabled="options.confirmInput && options.confirmInput !== internalConfirm"
                        @click="onOk"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
    import Vue, { PropType, VueConstructor } from 'vue'
    import { DialogComponent } from 'vue-modal-dialogs'
    import { BFormCheckbox, BFormInput } from 'bootstrap-vue'
    import { DialogOptions } from './typings'
    import { STORAGE_PREFIX } from './index'

    export default (Vue as VueConstructor<
        Vue & DialogComponent<any>
    >).extend({
        components: {
            BFormCheckbox,
            BFormInput,
        },

        props: {
            options: { type: Object as PropType<DialogOptions>, required: true },
            type: { type: String as PropType<'CONFIRM' | 'ALERT'>, required: true },
        },

        data() {
            return {
                storageEnabled: false,
                internalConfirm: '',
            }
        },

        computed: {
            modalClass(): string | null {
                if (!this.options.size)
                    return null

                return `modal-${this.options.size}`
            },

            okIcon(): string | any[] | void {
                if (!this.options.okIcon) return

                return Array.isArray(this.options.okIcon)
                    ? this.options.okIcon
                    : ['fal', this.options.okIcon]
            },
        },

        methods: {
            onOk(): void {
                if (this.storageEnabled && this.options.storageKey)
                    this.$localStorage.set(STORAGE_PREFIX + this.options.storageKey, 'true')

                this.$close(true)
            },
        },
    })
</script>

<style lang="scss" scoped>
    @import '@scss/vue.scss';

    .alert-modal {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: $zindex-modal;
        overflow-x: none;
        overflow-y: auto;
        // Remove bottom margins on last child if there's any html content to preserve spacing
        &__html-content {
            :last-child {
                margin-bottom: 0;
            }
        }
    }

    // Modal transition
    .modal-fade-enter-active, .modal-fade-leave-active {
        transition: opacity 250ms ease-out;

        .modal-dialog {
            transition: transform 250ms ease-out;
        }
    }

    .modal-fade-enter, .modal-fade-leave-to {
        opacity: 0;

        .modal-dialog {
            transform: translate(0, -3.125rem);
        }
    }
</style>
