<template>
    <div
        class="base-modal"
        tabindex="-1"
        role="dialog"
        :reject="reject"
        :resolve="resolve"
        @mousedown.stop="outsideClick"
        @keyup.esc="outsideClick"
    >
        <spinner
            v-if="busy"
            spinner-class="centerer"
        />
        <div
            v-else
            class="modal-dialog"
            :class="dialogClass"
            role="document"
            autofocus
            @mousedown.stop
            @mouseup.stop
        >
            <div
                class="modal-content"
                :class="contentClass"
                :style="contentStyle"
            >
                <div
                    v-if="!noHeader"
                    class="modal-header"
                    :class="headerClass"
                >
                    <!--
                        @slot Overwrite the whole header as well the dismiss action
                        @binding {function} resolve Resolve modal promise
                        @binding {function} reject Reject  modal promise
                     -->
                    <slot
                        name="header"
                        :reject="reject"
                        :resolve="resolve"
                    >
                        <h5 class="modal-title">
                            <!--
                                @slot Overwrite the tile, alternative to title prop
                                how ever this will overwrite the title prop
                            -->
                            <slot name="title">
                                {{ title }}
                            </slot>
                        </h5>
                    </slot>
                    <fa
                        v-if="!blocking"
                        class="pointer ml-3"
                        size="lg"
                        :icon="['fal', 'times']"
                        :aria-label="$t('TOOLTIP.CLOSE')"
                        @click="reject('dismiss')"
                    />
                </div>

                <div
                    v-if="$slots.default || $scopedSlots.default"
                    class="modal-body"
                    :class="bodyClass"
                >
                    <!--
                        @slot The body of the modal
                        @binding {function} resolve Resolve modal promise
                        @binding {function} reject Reject  modal promise
                     -->
                    <slot
                        :reject="reject"
                        :resolve="resolve"
                    />
                </div>

                <div
                    v-if="!noFooter"
                    class="modal-footer"
                    :class="footerClass"
                >
                    <!--
                        @slot Overwrite the whole footer.
                        @binding {function} resolve Resolve modal promise
                        @binding {function} reject Reject  modal promise
                     -->
                    <slot
                        name="footer"
                        :reject="reject"
                        :resolve="resolve"
                    >
                        <!--
                            @slot Overwrite Cancel button
                            @binding {function} resolve Resolve modal promise
                            @binding {function} reject Reject  modal promise
                        -->
                        <slot
                            v-if="!noCancel"
                            name="reject-btn"
                            :reject="reject"
                            :resolve="resolve"
                        >
                            <btn
                                variant="default"
                                emphasis="low"
                                :label="$t('TERMS.CANCEL')"
                                @click="reject('cancel')"
                            />
                        </slot>

                        <!--
                            @slot Overwrite OK button
                            @binding {function} resolve Resolve modal promise
                            @binding {function} reject Reject  modal promise
                        -->
                        <slot
                            v-if="!noConfirm"
                            name="ok-btn"
                            :reject="reject"
                            :resolve="resolve"
                        >
                            <btn
                                variant="primary"
                                :label="$t('TERMS.OK')"
                                @click="resolve(true)"
                            />
                        </slot>
                    </slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
    import Vue, { PropType, VueConstructor } from 'vue'
    import Spinner from '@common/components/Spinner.vue'
    import SharedModalMixin from './SharedModalMixin'

    /**
     * Base modal, this is the most typical modal we use.
     * It consists of a header, body and footer, in most cases
     * we use this modal.
     */
    export default (Vue as VueConstructor<
        Vue & InstanceType<typeof SharedModalMixin>
    >).extend({
        components: {
            Spinner,
        },
        mixins: [ SharedModalMixin ],
        props: {
            /**
             * Title of the modal
             * - Can be overwritten with the `title` slot
             */
            title: {
                type: String as PropType<string>,
                default: null,
            },
            /**
             * Disable header completely
             */
            noHeader: {
                type: Boolean as PropType<boolean>,
                default: false,
            },

            /**
             * Disable footer completely
             */
            noFooter: {
                type: Boolean as PropType<boolean>,
                default: false,
            },
            /**
             * Disable default cancel button
             */
            noCancel: {
                type: Boolean as PropType<boolean>,
                default: false,
            },
            /**
             * Disable default confirmation button
             */
            noConfirm: {
                type: Boolean as PropType<boolean>,
                default: false,
            },
            /**
             * Set busy state on modal, only rendering backdrop with a spinner
             */
            busy: {
                type: Boolean as PropType<boolean>,
                default: false,
            },
            /**
             * Classes to apply on `modal-content`
             */
            contentClass: {
                type: String as PropType<string>,
                default: '',
            },

            /**
             * Styles to apply on `modal-content`
             */
            contentStyle: {
                type: [String, Object] as PropType<string | object>,
                default: '',
            },

            /**
             * Classes to apply on `modal-header`
             */
            headerClass: {
                type: [String, Array, Object] as PropType<string | any[] | object>,
                default: null,
            },
            /**
             * Classes to apply on `modal-body`
             */
            bodyClass: {
                type: [String, Array, Object] as PropType<string | any[] | object>,
                default: null,
            },
            /**
             * Classes to apply on `modal-footer`
             */
            footerClass: {
                type: [String, Array, Object] as PropType<string | any[] | object>,
                default: null,
            },
        },
    })
</script>

<style lang="scss" scoped>
    @import '@scss/vue.scss';

    .base-modal {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: $zindex-modal;
        overflow-x: none;
        overflow-y: auto;
    }
</style>
