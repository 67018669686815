type UserSession = Pick<SessionAttentionModel, 'attended' | 'status' | 'userStatus'>

function hasAttended(session?: UserSession): boolean {
    return session?.attended === true
}

function isAttending(session?: UserSession): boolean {
    return session?.userStatus === 'attending' && typeof session?.attended !== 'boolean'
}

function isWaiting(session?: UserSession): boolean {
    return session?.userStatus === 'waitingList'
}

function isDeclining(session?: UserSession): boolean {
    return session?.userStatus === 'notAttending'
}

function isInvited(session?: UserSession): boolean {
    return session?.userStatus === 'invited'
}

function isRequested(session?: UserSession): boolean {
    return session?.userStatus === 'requested'
}

function isPlanned(session?: UserSession): boolean {
    return session?.status === 'PLANNED'
}

export function canRegisterSession(
    markAttendance: attendanceType,
    markAttendanceAlternative: boolean,
    sessionStatus?: SessionStatus,
    userStatus?: SessionUserStatus,
    attended?: boolean | null,
): boolean {
    return (
        sessionStatus === 'HELD'
        && markAttendance === 'user'
        && markAttendanceAlternative
        && (userStatus === 'attending' || userStatus === 'requested')
        && attended === null
    )
}

export function getAttendingSession(sessions?: SessionModel[]): SessionModel | null {
    return sessions?.find((session) => {
        return session.user_has_course_session?.status === 'attending'
            && typeof session.user_has_course_session?.attended !== 'boolean'
    }) ?? null
}

export function getWaitingSession(sessions?: SessionModel[]): SessionModel | null {
    return sessions?.find((session) => {
        return session.user_has_course_session?.status === 'waitingList'
            && typeof session.user_has_course_session?.attended !== 'boolean'
    }) ?? null
}

function getUserStates(session: UserSession): SessionUserStatesModel {
    return {
        hasAttended: hasAttended(session),
        isAttending: isAttending(session),
        isPlanned: isPlanned(session),
        isDeclining: isDeclining(session),
        isInvited: isInvited(session),
        isWaiting: isWaiting(session),
        isRequested: isRequested(session),
    }
}


export function getUserStatus(session?: UserSession | null): SessionUserStatusDetails | null {
    if (!session)
        return null

    const result = getUserStates(session)

    if (!session?.userStatus) {
        if (!result.isInvited)
            return null

        return {
            variant: 'info',
            label: 'LEARNING.COURSE_SESSION_INVITE',
        }
    }

    if (typeof session.attended === 'boolean')
        return {
            variant: 'gray',
            label: session.attended
                ? 'SESSION.USER.STATUS.ATTENDED'
                : 'SESSION.USER.STATUS.NOT_ATTENDED',
        }

    if (result.isAttending)
        return {
            variant: result.isPlanned ? 'success' : 'gray',
            label: 'SESSION.USER.STATUS.ENROLLED',
        }

    if (result.isInvited)
        return {
            variant: result.isPlanned ?  'info' : 'gray',
            label: 'COURSE.STATUS.INVITED',
        }

    if (result.isRequested)
        return {
            variant: result.isPlanned ?  'warning' : 'gray',
            label: 'SESSION.USER.STATUS.REQUESTED',
        }

    if (result.isDeclining)
        return {
            variant: result.isPlanned ?  'danger' : 'gray',
            label: 'SESSION.USER.STATUS.DECLINED',
        }

    if (result.isWaiting)
        return {
            variant: result.isPlanned ?  'warning' : 'gray',
            label: 'SESSION.USER.STATUS.WAITINGLIST',
        }

    return null
}
