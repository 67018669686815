import { Extension } from '@tiptap/vue-2'

import Document from './document'
import Text from './text'
import TextStyle from './text-style'
import Typography, { TypographyOptions } from './typography'
import Paragraph, { ParagraphOptions } from './paragraph'
import Bold, { BoldOptions } from './bold'
import Italic, { ItalicOptions } from './italic'
import Underline, { UnderlineOptions } from './underline'
import Strike, { StrikeOptions } from './strike'
import HardBreak, { HardBreakOptions } from './hard-break'
import Heading, { HeadingOptions } from './heading'
import FontFamiliy, { FontFamilyOptions } from './font-family'
import FontSize, { FontSizeOptions } from './font-size'
import TextAlign, { TextAlignOptions } from './text-align'
import List, { ListOptions } from './list'
import TextColor, { TextColorOptions } from './text-color'
import History, { HistoryOptions } from './history'
import Variable, { VariableOptions } from './variable'
import Placeholder, { PlaceholderOptions } from './placeholder'
import Link, { LinkOptions } from './link'
import CharacterCount, { CharacterCountOptions } from './character-count'
import OpenAi, { OpenAiOptions } from './openai'
import Media, { MediaOptions } from './media'
import Table, { TableOptions } from './table'

export interface TiptapOptions {
    /**
     * Turns of all extensions then you can re-enable them individually
     */
    plain?: boolean;

    /**
     * Manage allowed content on the root document
     * @default block+
     * @see https://tiptap.dev/docs/editor/core-concepts/schema#content
     */
    documentContent?: string;

    typography?: Partial<TypographyOptions> | boolean;
    paragraph?: Partial<ParagraphOptions> | false;
    bold?: Partial<BoldOptions> | boolean;
    italic?: Partial<ItalicOptions> | boolean;
    underline?: Partial<UnderlineOptions> | boolean;
    strike?: Partial<StrikeOptions> | boolean;
    hardBreak?: Partial<HardBreakOptions> | boolean;
    heading?: Partial<HeadingOptions> | boolean;
    fontFamily?: Partial<FontFamilyOptions> | boolean;
    fontSize?: Partial<FontSizeOptions> | boolean;
    textAlign?: Partial<TextAlignOptions> | boolean;
    list?: Partial<ListOptions> | boolean;
    textColor?: Partial<TextColorOptions> | boolean;
    history?: Partial<HistoryOptions> | boolean;
    placeholder?: Partial<PlaceholderOptions> | string;
    variable?: Partial<VariableOptions>;
    link?: Partial<LinkOptions> | boolean;
    characterCount?: Partial<CharacterCountOptions> | boolean
    openAi?: Partial<OpenAiOptions> | boolean;
    media?: Partial<MediaOptions> | boolean;
    table?: Partial<TableOptions> | boolean;
    multiLine?: boolean;
    autofocus?: boolean;
    events?: {
        enter?: (event: any) => boolean | void;
        esc?: (event: any) => boolean | void;
        shiftEnter?: (event: any) => boolean | void;
    };
}

const Configuration = Extension.create<TiptapOptions>({
    name: 'configuration',

    addExtensions() {
        const { plain } = this.options

        const extensions: any[] = [
            Document.extend({
                content: this.options.documentContent ?? 'block+',
            }),
            Text,
        ]

        const shouldHaveExt = (option: TiptapOptions[keyof TiptapOptions]): boolean => {
            return !!((plain && option) || (!plain && option !== false))
        }

        /**
         * Default extensions
         * - These extensions are enabled by default if plain option is not set
         * - if plain option is set you can enable these extension by passing a boolean or a option object
         */
        if (this.options.paragraph !== false)
            extensions.push(Paragraph.configure(this.options?.paragraph))

        if (shouldHaveExt(this.options.typography))
            extensions.push(Typography.configure(
                typeof this.options.typography === 'object' ? this.options?.typography : undefined,
            ))

        if (shouldHaveExt(this.options.bold))
            extensions.push(Bold.configure(
                typeof this.options.bold === 'object' ? this.options?.bold : undefined,
            ))

        if (shouldHaveExt(this.options.italic))
            extensions.push(Italic.configure(
                typeof this.options.italic === 'object' ? this.options?.italic : undefined,
            ))

        if (shouldHaveExt(this.options.underline))
            extensions.push(Underline.configure(
                typeof this.options.underline === 'object' ? this.options?.underline : undefined,
            ))

        if (shouldHaveExt(this.options.strike))
            extensions.push(Strike.configure(
                typeof this.options.strike === 'object' ? this.options?.strike : undefined,
            ))

        if (shouldHaveExt(this.options.hardBreak))
            extensions.push(HardBreak.configure(
                typeof this.options.hardBreak === 'object' ? this.options?.hardBreak : undefined,
            ))

        if (shouldHaveExt(this.options.heading))
            extensions.push(Heading.configure(
                typeof this.options.heading === 'object' ? this.options?.heading : undefined,
            ))

        if (shouldHaveExt(this.options.fontFamily))
            extensions.push(TextStyle, FontFamiliy.configure(
                typeof this.options.fontFamily === 'object' ? this.options?.fontFamily : undefined,
            ))

        if (shouldHaveExt(this.options.textAlign))
            extensions.push(TextAlign.configure(
                typeof this.options.textAlign === 'object' ? this.options?.textAlign : undefined,
            ))

        if (shouldHaveExt(this.options.list))
            extensions.push(List.configure(
                typeof this.options.list === 'object' ? this.options?.list : undefined,
            ))

        if (shouldHaveExt(this.options.textColor))
            extensions.push(TextStyle, TextColor.configure(
                typeof this.options.textColor === 'object' ? this.options?.textColor : undefined,
            ))

        if (shouldHaveExt(this.options.history))
            extensions.push(History.configure(
                typeof this.options.history === 'object' ? this.options?.history : undefined,
            ))

        if (shouldHaveExt(this.options.link))
            extensions.push(Link.configure(
                typeof this.options.link === 'object' ? this.options.link : undefined,
            ))


        /**
         * Non-default extensions
         * - These extensions you'll need to explicitly define in config options.
         */
        if (this.options.characterCount)
            extensions.push(CharacterCount.configure(
                typeof this.options.characterCount === 'object' ? this.options.characterCount : undefined,
            ))

        if (this.options.fontSize)
            extensions.push(TextStyle, FontSize.configure(
                typeof this.options.fontSize === 'object' ? this.options.fontSize : undefined,
            ))

        if (this.options.variable)
            extensions.push(Variable.configure(this.options?.variable))

        if (this.options.placeholder)
            extensions.push(Placeholder.configure(
                typeof this.options.placeholder === 'string'
                    ? { placeholder: this.options.placeholder }
                    : this.options?.placeholder,
            ))

        if (this.options.openAi)
            extensions.push(OpenAi.configure(
                typeof this.options.openAi === 'object' ? this.options.openAi : undefined,
            ))

        if (this.options.media)
            extensions.push(Media.configure(
                typeof this.options.media === 'object' ? this.options.media : undefined,
            ))

        if (this.options.table)
            extensions.push(Table.configure(
                typeof this.options.table === 'object' ? this.options.table : undefined,
            ))

        return [ ...new Set(extensions) ]
    },

    addKeyboardShortcuts() {
        return {
            'Shift-Enter': (event: any): boolean => {
                if (this.options.events?.shiftEnter)
                    return this.options.events?.shiftEnter(event) || true

                return false
            },

            'Enter': (event: any): boolean => {
                if (this.options.events?.enter)
                    return this.options.events?.enter(event) || true

                if (!this.options.multiLine)
                    return true

                return false
            },

            'Escape': (event: any): boolean => {
                if (this.options.events?.esc)
                    return this.options.events?.esc(event) || true

                return false
            },
        }
    },
})

export default Configuration
