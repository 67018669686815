<template>
    <overlay
        overlay-class="d-flex flex-column rounded click-through p-3 text-white"
        variant="default"
        :hover="!horizontal"
    >
        <template #default="{ hovering }">
            <card
                :class="{
                    'pointer': selectable && !disabled,
                    'opacity-50': disabled,
                }"
                :body-class="{
                    'd-flex align-items-center g-3': true,
                    'justify-content-between py-2': horizontal,
                }"
                :footer-class="{
                    'bg-white': !horizontal && !hovering,
                    'py-2': !horizontal,
                }"
                :ar="ar"
                :variant="getVariant(hovering)"
                v-bind="$attrs"
                @click="onClick"
            >
                <template v-if="horizontal">
                    <fa
                        size="2x"
                        :icon="['fas', icon.icon]"
                        :class="`text-${icon.variant}`"
                    />
                    <div class="flex-grow-1 text-truncate">
                        <span
                            class="text-body"
                            v-text="internalValue.name"
                        />

                        <div
                            class="d-flex flex-wrap"
                            :class="{
                                'align-items-center': horizontal,
                                'flex-column align-items-start': !horizontal
                            }"
                        >
                            <small
                                v-if="context?.createdAt"
                                class="text-muted mr-2"
                                style="flex-shrink: 0;"
                                v-text="`${$t('TERMS.UPLOADED')} ${$filter('date')(context.createdAt)}`"
                            />

                            <span
                                v-if="$scopedSlots['after-details'] && context && horizontal"
                                class="mr-2"
                                v-text="'•'"
                            />

                            <slot name="after-details" />
                        </div>
                    </div>

                    <resources-download-a
                        v-if="downloadable"
                        :path="internalValue.path"
                        class="text-link ml-auto p-0"
                        tag="a"
                    />

                    <!--
                        @slot Add content on the right most side of card body
                    -->
                    <slot name="after" />
                </template>

                <template v-else-if="!hovering">
                    <fa
                        class="mx-auto"
                        :icon="['fal', icon.icon]"
                        :class="`text-${icon.variant}`"
                        size="2x"
                    />
                </template>

                <template
                    v-if="!horizontal"
                    #footer
                >
                    <span
                        class="text-body text-truncate"
                        :class="{
                            'invisible': hovering
                        }"
                        v-text="internalValue.name"
                    />
                </template>
            </card>
        </template>

        <template
            v-if="!horizontal"
            #content
        >
            <span
                v-line-clamp.fixed="2"
                v-text="internalValue.name"
            />
            <small
                v-if="internalValue.size"
                v-text="$filter('prettyBytes')(internalValue.size)"
            />

            <resources-download-a
                v-if="canDownload"
                :path="internalValue.path"
                class="mt-auto ml-auto"
                size="sm"
            />
        </template>
    </overlay>
</template>

<script lang="ts">
    import Vue, { PropType, VueConstructor } from 'vue'
    import AspectRatioMixin from '@common/mixins/AspectRatioMixin'
    import Card from '@common/Card/Card.vue'
    import Overlay from '@common/components/Overlay.vue'
    import ResourcesDownloadA from '@common/Resources/components/ResourcesDownloadA.vue'
    import { getFaForMime } from '@utils'

    export default (Vue as VueConstructor<
        Vue &
        InstanceType<typeof AspectRatioMixin>
    >).extend({
        components: {
            Card,
            Overlay,
            ResourcesDownloadA,
        },

        mixins: [AspectRatioMixin],

        props: {
            /**
             * File model object
             */
            value: {
                type: Object as PropType<FileModel & { _selected?: boolean }>,
                required: true,
            },

            context: {
                type: Object as PropType<{ createdAt: string }>,
                default: null,
            },

            horizontal: {
                type: Boolean as PropType<boolean>,
                default: false,
            },

            /**
             * Display a button to download file
             */
            downloadable: {
                type: Boolean as PropType<boolean>,
                default: false,
            },

            selectable: {
                type: Boolean as PropType<boolean>,
                default: false,
            },

            disabled: {
                type: Boolean as PropType<boolean>,
                default: false,
            },
        },

        computed: {
            internalValue: {
                get(): FileModel & { _selected?: boolean } {
                    return this.value
                },

                set(value: FileModel & { _selected?: boolean }) {
                    this.$emit('input', value)
                },
            },

            canDownload(): boolean {
                return this.downloadable && Boolean(this.internalValue.path)
            },

            icon(): { icon: string; type: string; variant: string } {
                return getFaForMime(this.internalValue.mimeType, true)
            },
        },

        methods: {
            getFaForMime,

            onClick(): void {
                if (this.disabled || !this.selectable)
                    return

                this.internalValue._selected
                    ? this.deselect()
                    : this.select()
            },

            select(): void {
                this.internalValue._selected = true
                this.$emit('select', this.internalValue)
            },

            deselect(): void {
                this.internalValue._selected = false
                this.$emit('deselect', this.internalValue)
            },

            download(): void {
                this.$store.dispatch('Resource/download', this.internalValue.path)
            },

            getVariant(hovering: boolean): string {
                if (!this.disabled && this.internalValue._selected)
                    return 'neutral-5'

                if (this.horizontal)
                    return 'default'

                return hovering ? 'default' : 'gray-light'
            },
        },
    })
</script>
