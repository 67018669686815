<template>
    <bottom-sheet-modal
        v-if="iframeConfig && canUseKnowbot"
        ref="bottomSheet"
        :mode="bottomSheetMode"
        :background-color="bgColor"
    >
        <knowbot-iframe
            ref="knowbot"
            :config="iframeConfig"
            @close="closeModal"
        />
    </bottom-sheet-modal>
</template>

<script lang="ts">
    import Vue from 'vue'
    import BottomSheetModal from '@common/Modals/BottomSheetModal.vue'
    import KnowbotIframe from './KnowbotIframe.vue'
    import { get } from 'vuex-pathify'
    import { buildFullPath } from '@common/Resources/utils'
    import { getInit } from '../utils'
    import store from '@store'

    export default Vue.extend({
        components: {
            BottomSheetModal,
            KnowbotIframe,
        },

        data() {
            return {
                iframeConfig: null as KnowbotChatConfig | null,
                userGroups: [] as DisplayUserGroupModel[],
            }
        },

        computed: {
            knowbot(): KnowbotModel | null {
                return this.$store.state.Knowbot?.knowbot || null
            },
            canUseKnowbot(): boolean {
                if (!this.knowbot)
                    return false

                return this.knowbot.trainedAt !== null
                    && this.knowbot.externalId !== null
            },
            bottomSheetMode(): 'modal' | 'sheet' {
                return this.$device.isDesktop ? 'modal' : 'sheet'
            },
            imagePath: get<UserModel['image_path']>('Auth/authUser@image_path'),
            bgColor(): string {
                return this.iframeConfig?.settings?.backgroundColor || '#fff'
            },
        },

        watch: {
            knowbot() {
                this.initData()
            },
        },

        created() {
            if (this.knowbot) {
                this.initData()
            }
        },

        mounted() {
            this.$root.$on('open-knowbot', this.openModal)
            this.$root.$on('close-knowbot', this.closeModal)
        },

        beforeDestroy() {
            this.$root.$off('open-knowbot', this.openModal)
            this.$root.$off('close-knowbot', this.closeModal)
        },

        methods: {
            async initData() {
                if (!this.canUseKnowbot) return

                await store.load('UserGroups')
                const userGroups = await store.dispatch('UserGroups/Learner/get')
                this.userGroups = userGroups
                this.generateIframeConfig()
            },
            openModal() {
                (this.$refs.bottomSheet as InstanceType<typeof BottomSheetModal>).showSheet()
            },
            closeModal() {
                (this.$refs.bottomSheet as InstanceType<typeof BottomSheetModal>).hideSheet()
            },
            generateIframeConfig() {
                const data = {
                    settings: {
                        ...(this.imagePath && {
                            customAvatarImageUrl: buildFullPath(this.imagePath),
                        }),
                    },
                    groupIds: this.userGroups.map((group: DisplayUserGroupModel) => group.uuid),
                }

                const config = getInit(data)

                this.iframeConfig = config
            },
        },
    })

</script>