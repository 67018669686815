<template>
    <base-modal
        centered
        scrollable
        size="lg"
        :title="title || $t('IMAGE.EDIT')"
    >
        <image-cropper
            ref="cropper"
            class="mh-100"
            :local-actions="false"
            v-bind="$props.arguments"
        />

        <template #ok-btn>
            <btn
                variant="primary"
                :label="$t('TERMS.SAVE')"
                @click="submit"
            />
        </template>
    </base-modal>
</template>

<script lang="ts">
    import Vue, { VueConstructor, PropType } from 'vue'
    import BaseModal from './BaseModal.vue'
    import ImageCropper from '@common/Images/ImageCropper.vue'
    import { DialogComponent } from 'vue-modal-dialogs'

    interface Refs {
        $refs: {
            cropper: InstanceType<typeof ImageCropper>;
        };
    }

    /**
     * Image cropper modal wrapper
     * - Inherits all props from the ImageCropper component
     * - additional title prop for modal title
     */
    export default (Vue as VueConstructor<Vue & Refs & DialogComponent<File>>).extend({
        components: {
            BaseModal,
            ImageCropper,
        },

        props: {
            title: {
                type: String as PropType<string>,
                default: null,
            },
        },

        methods: {
            async submit(): Promise<void> {
                const file = await this.$refs.cropper.crop()

                return this.$close(file)
            },
        },
    })
</script>