<template>
    <tiptap-editor
        v-model="message"
        class="open-ai-input"
        mode="inline"
        :toolbar="false"
        :options="options"
        :disabled="disabled"
        :editor-attributes="{
            enterkeyhint: 'send'
        }"
    />
</template>

<script lang="ts">
    import Vue, { PropType } from 'vue'
    import { get } from 'vuex-pathify'
    import { TiptapEditor, TiptapOptions } from '@common/RichText/Tiptap'
    import { stripTags } from '@utils'

    export default Vue.extend({
        components: {
            TiptapEditor,
        },

        props: {
            placeholder: {
                type: String as PropType<string>,
                default: '',
            },

            disabled: {
                type: Boolean as PropType<boolean>,
                default: false,
            },
        },

        data() {
            return {
                message: '',
            }
        },

        computed: {
            busy: get<boolean>('OpenAI/busy'),
            options(): TiptapOptions {
                return {
                    plain: true,
                    hardBreak: true,
                    autofocus: true,
                    multiLine: false,
                    placeholder: this.placeholder,
                    characterCount: {
                        limit: 200,
                    },
                    events: {
                        enter: () => this.submit(),
                    },
                }
            },
        },

        methods: {
            submit(): void {
                if (!this.message || this.disabled)
                    return

                this.$emit('submit', stripTags(this.message, true))
                this.message = ''
            },
        },
    })
</script>

<style lang="scss" scoped>
    @import "@scss/vue.scss";

    .open-ai-input {
        flex-direction: row !important;
        padding: spacer(3);
        border-radius: $border-radius-lg;
        background: color('gray-lighter');

        ::v-deep(.tiptap-editor__content) {
            flex-grow: 1;
        }

        ::v-deep(.tiptap-editor__counter) {
            color: color('gray');
            font-size: $font-size-sm;
            align-self: flex-start;
            margin: 0 !important;
        }
    }
</style>
