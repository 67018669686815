export const KNOWBOT_STATUS = {
    ['MISSING-CONFIGURATION']: 'MISSING-CONFIGURATION',
    TRAINED: 'TRAINED',
    TRAINING: 'TRAINING',
    READY: 'READY',
    ERROR: 'ERROR',
    UNKNOWN: 'UNKNOWN',
}

export const KNOWBOT_RESOURCE_STATUS = {
    UPLOADED: 'UPLOADED',
    TRAINED: 'TRAINED',
    DELETED: 'DELETED',
    REQUIRES_RETRAINING: 'REQUIRES_RETRAINING',
    TRAINING: 'TRAINING',
    UPDATING: 'UPDATING',
    UPLOADING: 'UPLOADING',
    DELETING: 'DELETING',
} as Record<KnowbotResourceModel['status'], KnowbotResourceModel['status']>


export const KNOWBOT_RESOURCE_STATUS_COUNT = {
    UPLOADED: 'KNOWBOT.TRAINING_AREA.UPLOADED.COUNT',
    TRAINED: 'KNOWBOT.TRAINING_AREA.TRAINED.COUNT',
    DELETED: 'KNOWBOT.TRAINING_AREA.DELETED.COUNT',
    REQUIRES_RETRAINING: 'KNOWBOT.TRAINING_AREA.REQUIRES_RETRAINING.COUNT',
} as Record<KnowbotResourceStatus, string>


export const KNOWBOT_RESOURCE_STATUS_ICON = {
    UPLOADED: ['fas', 'circle'],
    TRAINED: ['fas', 'circle'],
    DELETED: ['fal', 'trash-alt'],
    REQUIRES_RETRAINING: ['fal', 'arrows-rotate'],
} as Record<KnowbotResourceStatus, [string, string]>


export const KNOWBOT_RESOURCE_STATUS_VARIANT = {
    UPLOADED: 'warning',
    TRAINED: 'success',
    DELETED: 'danger',
    REQUIRES_RETRAINING: 'info',
} as Record<KnowbotResourceStatus, 'success' | 'warning' | 'danger' | 'info'>

export const KNOWBOT_RESOURCE_LOADED_STATE = new Set([
    KNOWBOT_RESOURCE_STATUS.UPLOADED,
    KNOWBOT_RESOURCE_STATUS.TRAINED,
    KNOWBOT_RESOURCE_STATUS.DELETED,
    KNOWBOT_RESOURCE_STATUS.REQUIRES_RETRAINING,
])

export const KNOWBOT_RESOURCE_LOADING_STATE = new Set([
    KNOWBOT_RESOURCE_STATUS.TRAINING,
    KNOWBOT_RESOURCE_STATUS.UPDATING,
    KNOWBOT_RESOURCE_STATUS.UPLOADING,
    KNOWBOT_RESOURCE_STATUS.DELETING,
])
