import Vue from 'vue'
import { get } from 'vuex-pathify'

export default Vue.extend({
    computed: {
        knowbot: get<KnowbotModel | null>('Knowbot/knowbot'),

        canUseKnowbot(): boolean {
            if (!this.knowbot)
                return false

            return this.knowbot.trainedAt !== null
                && this.knowbot.externalId !== null
        },

        isMobile(): boolean {
            return this.$device.isMobile
        },
    },

    methods: {
        openKnowbotModal(): void {
            if (!this.canUseKnowbot || !this.knowbot)
                return

            this.$root.$emit('open-knowbot')
        },
    },
})
