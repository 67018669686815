<template>
    <div class="d-flex flex-column">
        <!-- Me -->
        <div
            v-if="value.prompt"
            class="d-flex flex-column align-items-end"
        >
            <div class="chat-bubble-container mb-3 d-inline-flex align-items-center">
                <div class="chat-bubble-actions" />
                <div
                    class="chat-bubble chat-bubble--me"
                    :class="{ 'opacity-40 border-warning': value.flagged }"
                >
                    {{ value.prompt }}
                </div>
            </div>
        </div>

        <!-- Them -->
        <open-ai-policy-violation-message v-if="value.flagged" />
        <div
            v-else
            class="d-flex flex-column align-items-start"
        >
            <div
                v-for="(item, key) in value.response"
                :key="key"
                class="chat-bubble-container mb-3 d-inline-flex align-items-center"
            >
                <div
                    class="chat-bubble chat-bubble--them"
                    :style="{ '--gradient-deg': randomDeg }"
                >
                    <RichTextDisplay :value="mdParse(item.content)" />
                </div>
                <div class="chat-bubble-actions">
                    <copy-link
                        v-if="!disableCopy"
                        :text="item.content"
                        class="text-muted p-3 h-auto w-auto"
                        icon="copy"
                        variant="link"
                        @copied="onCopy"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
    import { defineComponent, PropType } from 'vue'
    import { marked } from 'marked'
    import OpenAiPolicyViolationMessage from './OpenAiPolicyViolationMessage.vue'
    import CopyLink from '@common/components/CopyLink.vue'
    import { RichTextDisplay } from '@common/RichText'

    export default defineComponent({
        components: {
            CopyLink,
            RichTextDisplay,
            OpenAiPolicyViolationMessage,
        },
        props: {
            value: {
                type: Object as PropType<OpenAIMessage>,
                required: true,
            },
            disableCopy: {
                type: Boolean as PropType<boolean>,
                default: false,
            },
        },

        data() {
            return {
                randomDeg: Math.floor(Math.random() * 361) + 'deg',
            }
        },

        methods: {
            onCopy(): void {
                this.$flash('success', this.$t('TERMS.COPIED'))
            },

            mdParse(value: string): string {
                return marked.parse(value) as string
            },
        },
    })
</script>

<style lang="scss" scoped>
    @import "@scss/vue.scss";

    .chat-bubble-container {
        &:hover .chat-bubble-actions {
            visibility: visible;
        }
    }

    .chat-bubble-actions {
        min-width: spacer(4);

        @include media-breakpoint-up(md) {
            visibility: hidden;
        }
    }

    .chat-bubble {
        border: $border-width * 2 solid transparent;
        padding: spacer(2) spacer(3);
        border-radius: $border-radius-lg * 1.5;

        &--them {
            background:
                linear-gradient(rgba($white, 0.8), rgba($white, 0.8)) padding-box,
                conic-gradient(
                    from var(--gradient-deg, 180deg),
                    rgba($lb-soft-apricot,.5),
                    rgba($lb-soft-indigo,.5),
                    rgba($lb-soft-apricot,.5)
                ) border-box;
        }

        &--me {
            border-color: $gray-150;
            background-color: $gray-150;
        }
    }

</style>