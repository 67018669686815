<template>
    <component
        :is="tag"
        class="rich-text-display fr-view"
        v-html="valueLocal"
    />
</template>

<script lang="ts">
    import Vue, { PropType } from 'vue'
    export default Vue.extend({
        props: {
            /**
             * Html content to display
             */
            value: {
                type: String as PropType<string>,
                default: null,
            },
            /**
             * Wrapping element for content.
             */
            tag: {
                type: String as PropType<string>,
                default: 'div',
            },
        },

        computed: {
            valueLocal(): string {
                if (!this.value) return ''

                /**
                 * Parsing the html string and modify any video src's
                 * to combat iOS missing thumbnails
                 * @see https://muffinman.io/blog/hack-for-ios-safari-to-display-html-video-thumbnail/
                 */
                const VIDEO_SRC = /(<video[^>]+src="([^"]+))/g

                return this.value.replace(VIDEO_SRC, '$1#t=0.001')
            },
        },
    })
</script>

<style lang="scss" scoped>
    @import "@scss/vue.scss";

    // Reset font weight on headers
    ::v-deep(h1),
    ::v-deep(h2),
    ::v-deep(h3),
    ::v-deep(h4),
    ::v-deep(h5),
    ::v-deep(h6) {
        font-weight: normal;
    }

    // remove bottom margin on last block
    ::v-deep(>*:last-child) {
        margin-bottom: 0;
    }

    // Image styles on tiptap editors images
    ::v-deep(img), ::v-deep(video) {
        display: inline-block;
        float: none;
        // margin: spacer(2) auto;
        max-width: 100%;
        vertical-align: baseline;

        &[data-display='inline'] {
            margin-left: spacer(2);
            margin-right: spacer(2);
        }

        &[data-display='block'] {
           display: block;
        }

        &[data-display='left'] {
            float: left;
            margin-left: 0;
            margin-right: spacer(2);
        }
        &[data-display='right'] {
            float: right;
            margin-left: spacer(2);
            margin-right: 0;
        }
    }

    // Table styles
    ::v-deep(table) {
        width: 100%;
        table-layout: fixed;

        td, th {
            border: $border-width solid $gray-200;
            padding: ($table-cell-padding/2) $table-cell-padding;
            > * { margin-bottom: 0 }
        }

        th {
            background-color: $gray-150;
            font-weight: bold;
        }
    }
</style>