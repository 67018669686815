<template>
    <validation-provider
        ref="provider"
        #default="props"
        :vid="vid"
        :name="nameLocal"
        :rules="rules"
        :custom-messages="customMessages"
        :debounce="availableRules.includes('remote') ? 1500 : 0"
        :immediate="immediate"
        :skip-if-empty="skipIfEmpty"
        slim
    >
        <b-form-group
            :label="label"
            :label-class="labelClasses"
            :label-for="nameLocal"
            :description="description"
            :disabled="disabled"
            :invalid-feedback="getValidationError(props.errors)"
            :state="getValidationState(props)"
            :class="{ 'w-100': block }"
        >
            <div
                class="d-flex flex-wrap"
                :class="[inputClass]"
            >
                <!--
                    The actual v-model that validation provider listens to
                    for validation support. This needs to be the first v-model
                    it finds for it to work, don't move this.
                 -->
                <input
                    v-model="internalValue"
                    type="hidden"
                >
                <!--
                    @slot Left of dropdown button. **Only available in multiple mode** This slot can be used
                          to show selected values on the left side of dropdown button. Fx as badges, its
                          up to developer how to display it.
                          @binding {any | any[]} value Current value of field. Array if `multiple` prop is set
                          @binding {(item: any) => void} remove Removing item from selection
                          @binding {(item: any) => void} clear Clear selection

                 -->
                <slot
                    v-if="multiple"
                    name="left"
                    :value="internalValue"
                    :remove="removeItem"
                    :clear="clearSelection"
                />
                <!-- Dropdown -->
                <b-dropdown
                    ref="dropdown"
                    :variant="variant"
                    :style="dropdownStyles"
                    :toggle-class="toggleClassLocal"
                    :popper-opts="popperOpts"
                    :right="right"
                    :disabled="disabled"
                    :size="size"
                    :block="block"
                    :class="[dropdownClass, { 'w-100': block }]"
                    :boundary="boundary"
                    @hide="applySelection"
                    @shown="onShown"
                >
                    <!-- Dropdown button content -->
                    <template #button-content>
                        <!--
                            @slot Dropdown button content
                                  - This will overwrite the usage of the `buttonContentLabel` prop
                                @binding {any | any[]} value Current value of field. Array if `multiple` prop is set
                                @binding {any | any[]} selected Internal selected property
                                @binding {(item: any) => void} clear Clear selection
                                @binding {string} defaultBtnContent default button content
                         -->
                        <slot
                            name="button-content"
                            :value="internalValue"
                            :selected="selected"
                            :clear="clearSelection"
                            :default-btn-content="defaultBtnContent"
                        >
                            <div class="d-flex align-items-center">
                                <span
                                    v-if="placeholder && placeholderFixed && selected"
                                    class="text-placeholder mr-3"
                                    v-text="placeholder"
                                />
                                <span
                                    v-tooltip="{ delay: { show: 1000 } }"
                                    :title="defaultBtnContent"
                                    class="flex-grow-1 text-truncate"
                                    v-text="defaultBtnContent"
                                />
                                <fa
                                    v-if="caret"
                                    class="ml-3"
                                    size="xs"
                                    :icon="['fal', 'chevron-down']"
                                />
                            </div>
                        </slot>
                    </template>

                    <!-- Menu content -->
                    <template #default>
                        <!-- Sticky list top area -->
                        <b-dropdown-text
                            v-if="searchable"
                            class="dropdown-menu__top position-sticky top-0 border-bottom"
                            text-class="d-flex align-items-center"
                        >
                            <fa
                                class="text-muted"
                                :icon="['fal', 'search']"
                            />
                            <b-form-input
                                ref="search"
                                v-model="query"
                                class="border-0 py-0"
                                :placeholder="`${$t('TERMS.SEARCH')}...`"
                                debounce="500"
                                @keydown.esc.stop="clearSearch"
                            />
                            <fa
                                v-if="query"
                                class="pointer"
                                size="sm"
                                :icon="['fal', 'times']"
                                @click.stop="clearSearch"
                            />
                        </b-dropdown-text>

                        <!--
                            @slot Shows before the list, when dropdown is open
                         -->
                        <slot
                            name="before-list"
                            :query="query"
                        />

                        <!-- Taggable option when taggable is enabled -->
                        <b-dropdown-item-button
                            v-if="showTaggableOption"
                            class="pb-0 pt-0"
                            @click.capture.native.stop="onTag(query)"
                        >
                            <fa
                                :icon="['fa', 'plus']"
                                size="sm"
                                class="text-muted ml-1 mr-2"
                            />
                            {{ query }}
                            <span class="text-muted text-lowercase">({{ $t('TERMS.NEW') }})</span>
                        </b-dropdown-item-button>

                        <!-- Selectable items -->
                        <b-dropdown-item-button
                            v-for="(item, index) in optionsLocal"
                            :id="getDropdownItemId(item)"
                            :key="index"
                            :button-class="{ 'pb-0 pt-0': multiple }"
                            :active="selected
                                && !Array.isArray(selected)
                                && selected[trackBy] === item[trackBy]
                            "
                            :disabled="disabledOptionSelect(item)"
                            @click.capture.native.stop="onItemClick(item)"
                        >
                            <b-form-checkbox
                                v-if="multiple"
                                v-model="selected"
                                class="dropdown-item__checkbox"
                                :value="item"
                                :disabled="disabledOptionSelect(item)"
                                :unchecked-value="null"
                            >
                                <!--
                                    @slot Slot for custom option template
                                        @binding {any} option option
                                        @binding {string} search current search value
                                -->
                                <slot
                                    name="option"
                                    :search="query"
                                    :option="item"
                                >
                                    {{ getTextField(item) }}
                                </slot>
                            </b-form-checkbox>
                            <slot
                                v-else
                                name="option"
                                :search="query"
                                :option="item"
                            >
                                {{ getTextField(item) }}
                            </slot>
                        </b-dropdown-item-button>

                        <!-- No search results -->
                        <b-dropdown-text v-if="query && !optionsLocal.length && !taggable">
                            <!--
                                @slot No search results
                                    @binding {string} query The value of search query
                                    @binding {() => void} clear Clear search value
                             -->
                            <slot
                                name="no-result"
                                :query="query"
                                :clear="clearSearch"
                            >
                                <div
                                    class="text-center text-muted"
                                    v-text="$t('TERMS.FILTERS_NO_RESULTS')"
                                />
                            </slot>
                        </b-dropdown-text>
                        <!--
                            @slot Shows after the list, when dropdown is open
                         -->
                        <slot name="after-list" />

                        <!-- Sticky list bottom area -->
                        <b-dropdown-text
                            v-if="multiple && !(query && !optionsLocal.length && showTaggableOption)"
                            class="dropdown-menu__bottom position-sticky bottom-0 pt-0"
                            text-class="d-flex"
                        >
                            <btn
                                emphasis="low"
                                :label="$t('TERMS.CLEAR')"
                                :disabled="disabledClear"
                                @click="clearSelection"
                            />

                            <btn
                                class="ml-auto"
                                variant="primary"
                                :label="$t('TERMS.APPLY')"
                                :disabled="disabledApply"
                                @click="onApply"
                            />
                        </b-dropdown-text>
                    </template>
                </b-dropdown>
            </div>
        </b-form-group>
    </validation-provider>
</template>

<script lang="ts">
    import Vue, { PropType, VueConstructor } from 'vue'
    import { ValidationProvider } from 'vee-validate'
    import {
        BDropdown,
        BDropdownItemButton,
        BDropdownText,
        BFormInput,
        BFormCheckbox,
    } from 'bootstrap-vue'
    import VueScrollTo from 'vue-scrollto'
    import { cloneDeep, debounce, get, isEqual } from 'lodash'

    import SharedField from './mixin'
    import { DropdownPropsMixin } from '@common/Forms/mixins/dropdown.mixin'
    import { fuzzySearch, getUniqueString } from '@utils'

    interface Refs {
        $refs: {
            dropdown: InstanceType<typeof BDropdown>;
            provider: InstanceType<typeof ValidationProvider>;
            search: InstanceType<typeof BFormInput>;
        };
    }

    /**
     * A Dropdown for selecting items
     *
     * This field mirrors our `SelectAdvancedField` but is displayed as a dropdown with
     * a drop down button as trigger.
     *
     * *Notable features:*
     *
     *  - Searchable
     *  - Single or multiple select
     *  - Multiple slots for convenient overwrites
     *  - Custom button label formatter function
     *  - Taggable via search (only in multiple mode)
     *
     *
     * @example ./__docs__/SelectDropdownField.examples.md
     */
    export default (Vue as VueConstructor<
        Vue
        & Refs
        & InstanceType<typeof SharedField>
        & InstanceType<typeof DropdownPropsMixin>
    >).extend({
        components: {
            BDropdown,
            BDropdownItemButton,
            BDropdownText,
            BFormInput,
            BFormCheckbox,
        },

        mixins: [SharedField, DropdownPropsMixin],

        props: {
            /**
             * Array of items to render as options for select input
             */
            options: {
                type: Array as PropType<any[]>,
                required: true,
            },

            optionFormatter: {
                type: Function as PropType<(option: any) => any>,
                default: null,
            },

            menuMaxHeightContainer: {
                type: String as PropType<string>,
                default: null,
            },

            /**
             * Disable option
             */
            disabledOption: {
                type: Function as PropType<(item: any) => boolean>,
                default: undefined,
            },
        },

        data() {
            return {
                dropdownListId: `dropdown-list--${getUniqueString()}`,
                query: '',
                selected: null as null | any | any[],
                localApplyOnClose: this.applyOnClose,
                popperOpts: {
                    positionFixed: false,
                    modifiers: {
                        flip: {
                            flipVariationsByContent: true,
                        },
                    },
                },
                containerHeight: null as number | null,
                initialSelection: null as any,
            }
        },

        computed: {
            isSelected(): boolean {
                if (Array.isArray(this.internalValue)) return !!this.internalValue.length

                return this.internalValue !== null
            },

            hasInitialSelection(): boolean {
                const hasInitialSelection = Array.isArray(this.initialSelection)
                    ? !!this.initialSelection.length
                    : this.initialSelection !== null

                return hasInitialSelection && isEqual(this.initialSelection, this.internalValue) || false
            },

            optionIds(): any[] {
                return this.options.map((option: any) => option.id)
            },

            /**
             * Selected items missing in option list, when
             * the options came from a remote request it will
             * keep the selected items available and visible
            */
            selectedMissingInOptions(): any[] {
                if (!Array.isArray(this.selected)) return []

                return this.selected.filter((item: any) => !this.optionIds.includes(item.id))
            },

            optionsLocal(): any[] {
                let options = [...this.options]

                if (this.internalSearch && this.query) {
                    const properties = Array.isArray(this.searchable)
                        ? this.searchable
                        : undefined
                    options = fuzzySearch(this.options, this.query, properties)
                }

                if (!this.resetOnClose && this.selectedMissingInOptions.length) {
                    options = options.concat(this.selectedMissingInOptions)
                }

                return this.optionFormatter
                    ? options.map(this.optionFormatter)
                    : options
            },

            showTaggableOption(): boolean {
                if (this.multiple && this.taggable && this.query)
                    return !this.optionsLocal.some((item) => this.getTextField(item) === this.query)

                return false
            },

            toggleClassLocal(): any[] {
                return [{
                    'rounded-pill': this.pill,
                    'has-selection': this.isSelected,
                    'has-initial-selection': this.hasInitialSelection,
                }, this.toggleClass]
            },

            dropdownStyles(): Record<string, string> {
                const maxHeight = this.containerHeight
                    ? `${this.containerHeight}px`
                    : this.menuMaxHeight

                return {
                    '--menu-max-height': maxHeight,
                    '--menu-min-width': this.menuMinWidth,
                }
            },

            defaultBtnContent(): string {
                if (this.multiple && !this.internalValue?.length)
                    return this.placeholder
                        ? this.placeholder
                        : this.$tc('FORMS.OPTIONS_SELECTED', 0)
                else if (this.multiple && this.internalValue?.length)
                    return this.placeholder
                        ? `${this.placeholder} (${this.internalValue.length})`
                        : this.$tc('FORMS.OPTIONS_SELECTED', this.internalValue.length, {
                            count: this.internalValue.length })

                return typeof this.selected !== 'undefined' && this.selected !== null
                    ? this.buttonContentLabel?.(this.selected) ?? this.getTextField(this.selected)
                    : this.placeholder || this.$tc('FORMS.SELECT_OPTION_PLURAL', 1)
            },

            emptyValue(): any {
                return this.multiple ? [] : null
            },


            disabledClear(): boolean {
                if (!this.allowEmpty)
                    return true

                if (this.multiple)
                    return ![ ...this.selected ?? [], ...this.internalValue ?? []].length

                return !this.selected
            },

            disabledApply(): boolean {
                if (this.allowEmpty)
                    return false

                if (this.multiple)
                    return !this.selected?.length

                return !this.selected
            },
        },

        watch: {
            internalValue: {
                handler(value: any): void {
                    this.setSelected(value ? value : this.emptyValue)
                },
                immediate: true,
            },

            options(): void {
                this.setSelected(this.value ? this.value : this.emptyValue)
            },

            query(value, oldValue): void {
                if (value === oldValue) return

                /**
                 * Emit search query on change
                 * @property {string} query Search query
                 */
                this.$emit('search-change', value)
            },
        },

        created() {
            this.initialSelection = this.value
        },

        mounted() {
            const container: HTMLElement | null = this.menuMaxHeightContainer
                ? this.$el.closest(this.menuMaxHeightContainer)
                : null

            if (container)
                this.containerHeight = container.offsetHeight / 2

            // Add id to dropdown ul element to be able to scroll to selected item
            this.$el
                .querySelector('.dropdown-menu')
                ?.setAttribute('id', this.dropdownListId)

            /**
             * Set popper options to fixed mode if dropdown is rendered inside a modal due to bootstrap bug
             * @see https://github.com/twbs/bootstrap/issues/28513
             */
            if (this.$el.closest('.modal-dialog'))
                this.popperOpts.positionFixed = true
        },

        methods: {
            onShown(): void {
                if (this.searchable)
                    this.$refs.search?.focus()

                if (this.isScrollable())
                    this.scrollToSelected(this.dropdownListId, this.getSelectedDropdownItemId())
            },

            setSelected(value: any): void {
                if (!this.valueField)
                    return this.selected = this.multiple
                        ? cloneDeep(value ?? [])
                        : cloneDeep(value)

                if (this.multiple) {
                    const selectedNotApplied = this.selected?.map((item: any) => item[this.valueField]) ?? []
                    this.selected = this.options.filter((item) => {
                        return [
                            ...selectedNotApplied,
                            ...value,
                        ].includes(item[this.valueField])
                    })
                } else
                    this.selected = this.options.find((item) => item[this.valueField] === this.value) ?? null
            },

            /**
             * NOTE: Debouncing this method due to it being fired twice in some cases
             * this needs to be investigated further so we don't need the extra
             * debounce func dependency
             */
            applySelection: debounce(function(this: any) {
                if (!this.localApplyOnClose) {
                    if (this.resetOnClose)
                        this.clearSelection()

                    return
                }

                if (this.disabledApply)
                    return

                this.initialSelection = null
                this.localApplyOnClose = this.applyOnClose

                let newValue: any = null

                if (!this.valueField) {
                    newValue = this.selected
                } else if (this.multiple) {
                    newValue = [...new Set([
                        // Selected values but outside options (when paginating)
                        ...(this.value ?? []).filter((item: any) => !this.optionIds.includes(item)),
                        ...this.selected.map((item: any) => item[this.valueField]),
                    ])]
                } else {
                    newValue = this.selected?.[this.valueField] ?? null
                }

                this.validate(newValue).then(() => this.internalValue = newValue)


                if (this.resetOnClose)
                    this.clearSelection()
            }, 0),

            /**
             * Trigger validation on provider when rules are applied
             */
            async validate(value: any): Promise<boolean> {
                if (!this.rules)
                    return true

                const result = await this.$refs.provider.validate(value)
                this.$refs.provider.applyResult(result)
                this.$refs.provider.setFlags({
                    dirty: true,
                    pristine: false,
                    touched: true,
                    untouched: false,
                })

                return true
            },
            /**
             * Handle single select
             */
            onItemClick(item: any): void {
                if (this.disabledOptionSelect(item)) return
                if (this.multiple) return

                if (this.allowEmpty && this.selected?.[this.trackBy] === item[this.trackBy])
                    this.selected = null
                else if (this.multiple)
                    this.selected.push(item)
                else
                    this.selected = item

                this.$refs.dropdown.hide()

                if (this.clearOnSelect)
                    this.clearSearch()

                this.$emit('selected-item', item)
            },

            removeItem(item: any): void {
                if (this.disabledOption?.(item)) return
                const index = this.selected
                    .findIndex((selectedItem: any) => selectedItem[this.trackBy] === item[this.trackBy])

                if (index > -1) {
                    this.selected.splice(index, 1)
                    this.$nextTick(() => this.applySelection())
                }
            },

            clearSelection(): void {
                if (this.allowEmpty) {
                    this.internalValue = this.multiple ? [] : null
                    this.selected = this.multiple ? [] : null
                } else if (this.multiple) {
                    this.internalValue = [this.selected[0]]
                    this.selected = [this.selected[0]]
                }

                if (this.closeOnClear)
                    this.$refs.dropdown.hide()

                this.clearSearch()

                this.$emit('clear', this.selected)
            },

            clearSearch(): void {
                this.query = ''
            },

            onTag(value: string): void {
                /**
                 * Triggers when select is in taggable mode and user
                 * selects the taggable item.
                 */
                this.$emit('tag', value)
            },

            onApply(): void {
                this.localApplyOnClose = true
                this.clearSearch()
                this.$refs.dropdown.hide()
            },

            getTextField(item: object): string {
                if (typeof this.textField === 'function')
                    return this.textField(item)

                return get(item, this.textField)
            },

            getDropdownItemId(item?: any): string | null {
                if (!item)
                    return null

                const str = `${item[this.trackBy] || item}`

                return str
                    ? `option--${str.replaceAll('/', '-')}`
                    : `option--${getUniqueString()}`
            },

            getSelectedDropdownItemId(): string | null {
                if (!this.selected)
                    return null

                const item = Array.isArray(this.selected)
                    ? this.selected[0]
                    : this.selected

                return this.getDropdownItemId(item)
            },

            scrollToSelected: debounce(function(dropdownId: string, itemId?: string | null): void {
                if (!itemId)
                    return

                VueScrollTo.scrollTo(`#${itemId}`, {
                    container: `#${dropdownId}`,
                })
            }, 100),

            isScrollable(): boolean {
                const list = this.$el.querySelector(`#${this.dropdownListId}`)
                if (!list)
                    return false

                return list.scrollHeight > list.clientHeight
            },

            disabledOptionSelect(item: any): boolean {
                if (this.disabledOption?.(item))
                    return true

                if (this.multiple && this.multipleLimit && this.selected.length >= this.multipleLimit)
                    return !this.selected.some((selectedItem: any) => selectedItem[this.trackBy] === item[this.trackBy])

                return false
            },
        },
    })
</script>

<style lang="scss" scoped>
    @import '@scss/vue.scss';

    // Special placeholder text color overwrite
    ::v-deep .dropdown-toggle .text-placeholder {
        color: $input-placeholder-color;
    }

    // Special dropdown variant "input" to align button with our inputs
    ::v-deep .dropdown-toggle.btn-input {
        text-align: left;
        background-color: $input-bg;
        color: $input-placeholder-color;
        border-color: $input-border-color;

        &.disabled {
            opacity: 1;
            background-color: $input-disabled-bg;
        }

        &.has-selection {
            color: $input-color;

            &:not(.has-initial-selection) {
                border-color: $body-color;
            }
        }

        .text-placeholder {
            color: $gray-400;
        }

        &:hover, &:focus {
            &:not(.disabled) {
                border-color: $body-color;
            }
        }
    }


    ::v-deep .dropdown-menu {
        max-height: 60vh;
        min-width: 18rem;
        max-height: var(--menu-max-height);
        min-width: var(--menu-min-width);
        overflow-x: auto;
        padding: 0;
        z-index: $zindex-fixed;

        .b-dropdown-text {
            font-weight: inherit;
        }

        .dropdown-item {
            outline: none;

            &__checkbox {
                display: flex;
                align-items: center;

                .custom-control-label {
                    flex-grow: 1;
                    padding: $dropdown-item-padding-y 0;

                    &::before, &::after {
                        @include centerer(false, true);
                    }
                }
            }

            &.active {
                color: inherit;
                box-shadow: inset $border-width * 2 0 0 $dropdown-link-active-color;
            }
        }

        &__top, &__bottom {
            background: $dropdown-bg;
            z-index: $zindex-sticky;
        }

        &__bottom {
            padding-top: $dropdown-item-padding-y;

            .b-dropdown-text {
                padding: $dropdown-item-padding-x $dropdown-item-padding-y;
                border-top: $border-width solid $border-color;
            }
        }
    }

    //
    // Overwrite disabled state styling on checkboxes
    //
    ::v-deep .custom-control-input:disabled ~ .custom-control-label {
        color: inherit;
        cursor: not-allowed;
    }

    ::v-deep .custom-checkbox .custom-control-input:disabled ~ .custom-control-label::before {
        opacity: 1;
    }
</style>
