<template>
    <div
        v-if="loading"
        class="spinner"
        :class="[variantClass, sizeClass, animationClass, spinnerClass]"
    >
        <span class="ball ball1" />
        <span class="ball ball2" />
        <span class="ball ball3" />
    </div>

    <div v-else>
        <slot />
    </div>
</template>

<script lang="ts">
    import Vue, { PropType } from 'vue'

    /**
     * Spinner will display a loading state animation based on a boolean.
     * When boolean is falsy then the default slot will be shown
     */
    export default Vue.extend({
        props: {
            /**
             * Loader size
             * @values 2xs, xs, sm, md, lg, xl
             */
            size: {
                type: String as PropType<string>,
                default: 'md',
            },
            /**
             * Show loading animation if true
             */
            loading: {
                type: Boolean as PropType<boolean>,
                default: true,
            },
            /**
             * Loader color variant
             * @values primary, secondary, accent, mixed
             */
            variant: {
                type: String as PropType<string>,
                default: 'primary',
            },
            /**
             * Any class to attach to loader animation element
             */
            spinnerClass: {
                type: String as PropType<string>,
                default: '',
            },

            /**
             * Animation style of dots
             */
            animation: {
                type: String as PropType<string>,
                default: 'pulse',
                validator: (value: string): boolean => ['pulse', 'blink', 'wave'].includes(value),
            },
        },

        computed: {
            variantClass(): string {
                return `spinner--${this.variant}`
            },

            sizeClass(): string {
                return `spinner--${this.size}`
            },

            animationClass(): string {
                return `spinner--${this.animation}`
            },
        },

    })
</script>

<style lang="scss" scoped>
    @import '@scss/vue.scss';

    .spinner {
        display: flex;
        justify-content: center;
        gap: spacer(1);

        > .ball {
            width: 1.5rem;
            height: 1.5rem;
            background-color: color('gray-light');
            border-radius: 100%;
        }

        // Color Variants
        &--primary > .ball { background-color: whitelabel-color('primary'); }
        &--secondary > .ball { background-color: whitelabel-color('secondary'); }
        &--accent > .ball { background-color: whitelabel-color('accent'); }
        &--white > .ball { background-color: $white; }

        &--mixed > {
            .ball1 { background-color: whitelabel-color('primary'); }
            .ball2 { background-color: whitelabel-color('secondary'); }
            .ball3 { background-color: whitelabel-color('accent'); }
        }


        // Size variants
        &--2xs > .ball { height: .6rem; width: .6rem; }
        &--xs > .ball { height: .75rem; width: .75rem; }
        &--sm > .ball { height: 1rem; width: 1rem; }
        &--lg > .ball { height: 1.75rem; width: 1.75rem; }
        &--xl > .ball { height: 2rem; width: 2rem; }


        // Animation variants
        &--pulse {
            .ball {
                animation: ball-pulse 1.5s infinite ease-in-out both;
                &:nth-child(2) { animation-delay: 160ms; }
                &:nth-child(3) { animation-delay: 320ms; }
            }
        }

        &--blink {
           .ball {
                opacity: 0;
                animation: ball-blink 1s infinite;
                &:nth-child(1) { animation-delay: 0.3333s; }
                &:nth-child(2) { animation-delay: 0.6666s; }
                &:nth-child(3) { animation-delay: 0.9999s; }
           }
        }
        &--wave {
           .ball {
                animation: ball-wave 1s infinite;
                &:nth-child(1) { animation-delay: 0.3333s; }
                &:nth-child(2) { animation-delay: 0.6666s; }
                &:nth-child(3) { animation-delay: 0.9999s; }
           }
        }
    }

    @keyframes ball-wave {
        0% { transform: translateY(0px); }
        50% { transform: translateY(-0.3rem); }
        100% { transform: translateY(0px); }
    }

    @keyframes ball-blink {
        50% { opacity: 1; }
    }

    @keyframes ball-pulse {
        0%, 80%, 100% { transform: scale(0); }
        40% { transform: scale(1); }
    }
</style>

<docs>
**Output:**
```vue
    <template>
        <div>
            <button
                :disabled="busy"
                v-text="'Do async'"
                @click="doAsync"
            />

            <spinner
                :loading="busy"
                variant="primary"
                size="md"
            >
                <h4 class="my-4">Results shown when loaded</h4>
            </spinner>
        </div>
    </template>

    <script>
    export default {
        data() {
            return { busy: true, };
        },

        mounted() {
            this.doAsync()
        },

        methods: {
            async doAsync() {
                this.busy = true
                await this.timeout(3000)
                this.busy = false
            },

            timeout(ms) {
                return new Promise(resolve => setTimeout(resolve, ms));
            }
        }
    }
    </script>
````
</docs>