import Vue, { VueConstructor } from 'vue'
import Notifications from 'vue-notification'
import FlashMessage from './FlashMessage.vue'
import FlashWrapper from './FlashWrapper.vue'
import { FlashOptions, FlashTitle, FlashVariant } from './typings'

Vue.use(Notifications)

const DEFAULT_TIMEOUT_MS = 5000

const CustomFlash = {
    install(Vue: VueConstructor): void {
        Vue.component('flash-message', FlashMessage)
        Vue.component('flash-wrapper', FlashWrapper)
        Vue.mixin({
            methods: {
                $flash(variant: FlashVariant, message: FlashTitle, options?: FlashOptions): void {
                    const duration = (options?.timeout ?? DEFAULT_TIMEOUT_MS)

                    this.$notify({
                        type: variant,
                        title: String(message),
                        text: options?.text
                            ? String(options.text)
                            : undefined,
                        duration: duration === false
                            ? -1
                            : duration,
                        data: {
                            icon: options?.icon,
                            noIcon: options?.noIcon,
                            list: options?.list,
                            iconClass: options?.iconClass,
                            actions: options?.actions,
                            additional: options?.data,
                        },
                        group: options?.group ?? 'bottom',
                    })
                },

                $flashClear(group: 'top' | 'bottom' | string): void {
                    this.$notify({ group, clean: true })
                },
            },
        })
    },
}

Vue.use(CustomFlash)
